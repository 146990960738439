import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HeaderService } from '../service/header.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  totalPeopleBenefited : number = 0;
  totalWorkShops : number = 0;
  totalColleges : number = 0;
  totalJobOfferReceived : number = 0;

  spinner: boolean = true;

  constructor(private db: AngularFireDatabase, private router: Router) { }
  
  ngOnInit(): void {
    this.gettingBenifitesCountFromDb()
  }

  
  @ViewChild('homePageVideo') myVideo!: ElementRef;

  playPauseVideo() {
    if(this.myVideo.nativeElement.paused)
    this.myVideo.nativeElement.play();
    else
    this.myVideo.nativeElement.pause();
  }

  gettingBenifitesCountFromDb() {
    this.db.list('RYAdmin').valueChanges().subscribe((data: any) => {
      this.totalWorkShops = data[0].TotalWorkShops;
      this.totalColleges = data[0].TotalColleges;
      this.totalJobOfferReceived = data[0].TotalJobOfferReceived;
      this.totalPeopleBenefited = data[0].TotalBenefitedPeople;
      this.spinner = false;
    })
    // this.db.list('RY/surveyDetails').valueChanges().subscribe((data: any) => {
    //   this.totalPeopleBenefited = data.length
    // })
  }

  startSurvey() {
    // Form 1
    var phase1Form = [];
    phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    // Form 2
    var phase2Form = [];
    phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    // Form 3
    var phase3Form = [];
    phase3Form = JSON.parse(localStorage.getItem('surveyPhase3Form')!);
    // Form 4
    var phase4Form = [];
    phase4Form = JSON.parse(localStorage.getItem('surveyPhase4Form')!);

    if (phase1Form == null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['TakeSurvey'])
    } else if (phase1Form != null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['phase2']) 
    } else if (phase1Form != null && phase2Form != null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['phase3']) 
    } else if (phase1Form != null && phase2Form != null && phase4Form != null && phase4Form == null) {
      this.router.navigate(['phase4']) 
    } else if (phase1Form != null && phase2Form != null && phase4Form != null && phase4Form != null) {
      this.router.navigate(['phase5']) 
    }
  }

  storeSuccessStoryPersonNameInLocal(data: any) {
    localStorage.setItem('personNameForArticle', data);
    this.router.navigate(['/briefArticles']);
  }
}