<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container about_main_container">
    <div class="row">
        <div class="col">
            <!-- about content -->
            <!-- <div class="row">
                <div class="col">
                    <div class="about_main_head_content">
                        About us
                    </div>
                </div>
            </div> -->
            <div class="row">
                <div class="col">
                    <div class="about_RY_Head_main_content">
                        Rural Youth of India (RY India): Empowering Tomorrow's Leaders
                    </div>
                </div>
            </div>
            <!-- video -->
            <div class="row">
                <div class="col">
                    <video
                        src="https://firebasestorage.googleapis.com/v0/b/rural-youth-2f166.appspot.com/o/videos%2FRY2%201.mp4?alt=media&token=f99897b3-80de-44d4-be9b-16daaf190ee0"
                        width="900px" height="508px" type="video/mp4" class="about_video" autoplay [muted]="'muted'"
                        disablepictureinpicture loop controls controlsList="nodownload nofullscreen noplaybackrate"
                        (click)="playPauseVideo()" #aboutVideo></video>
                </div>
            </div>
            <!-- Scope -->
            <div class="row">
                <div class="col">
                    <div class="about_scope_head">
                        Our Scope
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="about_scope_body_content_row1">
                        RY India is committed to nurturing India's rural youth through initiatives such as skill and
                        career development training, research & innovation, financial support, awareness campaigns,
                        networking, and international collaboration.
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col">
                    <div class="about_scope_body_content_row2">
                        To achieve India's $5 trillion economic dream by 2024-2025, it is essential to see the rural 
                        economy as a source of wealth creation. Factors such as geographically distributed rural youth 
                        population, presence of graduate level education in rural youth, well-connected national 
                        highways, Internet access and the increase in smartphone usage among rural youth will have a 
                        positive impact to India's economic and social development when such resources are utilized 
                        properly. As a first step, some of the major problems currently facing by rural Indian youth 
                        need to be addressed. They are as follows: Relatively high unemployment rate, lack of technical 
                        skills when competing with urban youth, lack of relevant resources, limited access to work 
                        related information, lack of competent direction, lack of other basic skills suitable for the 
                        profession and many other factors.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="about_scope_body_content_row3">
                        Even after graduation, most rural youth remain in poverty due to lack of opportunities. 
                        Many rural youth do not yet have "soft skills": the ability to identify and propose new ideas, 
                        business opportunities, sales and marketing skills, and to understand the changing work and 
                        business environment that may contribute to greater productivity and employment. Therefore, 
                        a systematic approach is needed to address the above issues for rural youth. This includes 
                        identifying talent, developing capacity and integrating them into economic activities. 
                        A friendly environment must be created urgently to encourage the rural youth of the country 
                        in a supportive manner and to provide the necessary training and skills, which will help to 
                        enhance the overall economic growth of our country. Furthermore, the growing Indian rural youth 
                        need the right infrastructure and the appropriate opportunities to look for work or start their 
                        own business to develop skills. Therefore, taking all aspects into consideration, the Rural Youth
                        (RY) of India 2021 was formed with an emphasis on the skills, employment and entrepreneurship 
                        development of rural youth.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="about_scope_body_content_row4">
                        Rural Youth (RY) of India is a non-profit organization established with the objective of 
                        promoting rural youth in India. RY India helps to create stable employment with graduates 
                        and non-graduates in rural areas across India and to know the abundant job related resources 
                        available to them. It also provides support for new jobs and the development of a business 
                        ecosystem.
                    </div>
                </div>
            </div> -->
            <!-- Vision -->
            <div class="row">
                <div class="col">
                    <div class="about_vision_head_main_content">
                        Our Vision
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="about_vision_body_content">
                        We strive to create highly skilled, self-sufficient rural youth, equipped to contribute to
                        community growth and national development.
                    </div>
                </div>
            </div>
            <!-- Mission -->
            <div class="row">
                <div class="col">
                    <div class="about_mission_head_main_content">
                        Our Mission
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="about_mission_body_content_row1">
                        Our mission is multifaceted:
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="about_mission_body_content_row2">
                        1. Skill Training: <span>For employment or entrepreneurship.</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="about_mission_body_content_row3">
                        2. Career Guidance: <span>From world-class experts.</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="about_mission_body_content_row4">
                        3. Research & Innovation: <span>Creating relevant technologies.</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="about_mission_body_content_row5">
                        4. Financial Support: <span>In education, sports, agriculture, and creative fields.</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="about_mission_body_content_row6">
                        5. Awareness: <span>About uplifting government policies and services.</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="about_mission_body_content_row7">
                        6. Networking: <span>Facilitating information exchange.</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="about_mission_body_content_row8">
                        7. Global Collaboration: <span>With NGOs for shared ideas and experiences.</span>
                    </div>
                </div>
            </div>
            <!-- leadership -->
            <!-- <div class="row">
                <div class="col">
                    <div class="about_leadership_heading">Leadership</div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="about_leadership_content">
                        Our founder, <span>Dr. Jeevananthan Kannan,</span> is an alumnus of IIT Madras and Aalto University, Finland.
                        Born and brought up in the small village of Sarvoy Pudur, Salem, India, his roots have inspired
                        the passion and commitment that drive RY India's mission.
                    </div>
                </div>
            </div> -->
            <!-- Note -->
            <div class="row">
                <div class="col">
                    <div class="about_note_head_main_content">
                        Note
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="about_note_body_content_row1">
                        RY India is service-oriented with no monetary benefits and no affiliations to political or
                        community parties. All income supports the organization's development.
                        Join our mission to transform lives and foster growth in India's rural youth.
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col">
                    <div class="about_note_body_content_row2">
                        It is a one forum for sharing for all above mentioned objectives. Additionally, the RY India 
                        social media groups (facebook, linkedIn and Quora groups) are intended strictly for discussions 
                        related to youth empowerment. RY India will never encourage the personal, cultural, religious, 
                        regional, political or any irrelevant activities/posts in any of social media groups 
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>