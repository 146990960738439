<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container mainContainerForSuccessStoryBriefPage">
    <div (click)="redirectToStoriesPage()" class="backArrowOfStoryPage">
        <img class="backArrowImageInStoryPage" src="../../assets/icons/backArrowForSuccessStories.png" alt="back Arrow">
        <div class="backToStoriesText"> Back to stories </div>
    </div>
    <div class="dynamicSuccessStorySections" *ngIf="nameOfThePerson == 'Arunachalam Muruganandam' ">
        <div class="col-12 briefSuccessStoryPersonName">
            Arunachalam Muruganandam
        </div>
        <div class="col-12 briefSuccessStoryPersonPhoto">
            <img class="briefSuccessStoryPersonImageTag"
                src="../../assets/images/successStories/arunachalam muruganandam.jpg" alt="Success person Image">
        </div>
        <div class="col-12 briefSuccessStoryPersonArticle">
            Arunachalam Muruganantham is an Indian social entrepreneur from Coimbatore, Tamil Nadu. He is best known for
            his work in the field of women's health and for revolutionizing menstrual hygiene in rural India. Born in a
            poor family, Muruganantham did not have access to proper education and instead, started working as a welder
            in his early twenties. <br> <br>

            However, his drive to help improve the health and well-being of women in rural India led him to invent a
            low-cost machine for producing sanitary napkins. This machine enabled rural women to make and sell their own
            sanitary napkins, providing them with a source of income and improving their menstrual hygiene. <br> <br>

            Muruganantham's invention has had a significant impact on the health and lives of women in rural India, and
            has been recognized by numerous awards and honors, including the prestigious Padma Shri award from the
            Indian government. He continues to work towards improving the health and well-being of rural women and has
            inspired countless individuals with his passion and dedication.
        </div>
    </div>

    <div class="dynamicSuccessStorySections" *ngIf="nameOfThePerson == 'Rajendra Singh'">
        <div class="col-12 briefSuccessStoryPersonName">
            Rajendra Singh
        </div>
        <div class="col-12 briefSuccessStoryPersonPhoto">
            <img class="briefSuccessStoryPersonImageTag" src="../../assets/images/successStories/rajendra singh.jpg"
                alt="Success person Image">
        </div>
        <div class="col-12 briefSuccessStoryPersonArticle">
            Rajendra Singh, also known as the "Waterman of India," is an Indian environmentalist and water
            conservationist who has dedicated his life to addressing water scarcity issues in rural India. Born in 1959
            in the state of Rajasthan, Singh had limited formal education but was deeply inspired by traditional Indian
            water conservation practices. <br> <br>

            In the 1980s, he founded the Tarun Bharat Sangh, an organization dedicated to water conservation and
            management, particularly in the arid regions of Rajasthan. Under Singh's leadership, the organization has
            worked on various projects to revive traditional water harvesting systems and educate communities on
            sustainable water management practices. <br> <br>

            Singh's work has led to the recharging of numerous water bodies, including wells and ponds, and has helped
            to restore the water cycle in several villages. He has also been instrumental in resolving conflicts over
            water resources and promoting water conservation as a human right. <br> <br>

            In 2015, Singh was awarded the Stockholm Water Prize, considered the highest honor in the water community,
            for his pioneering work in water conservation and sustainable water management practices. He continues to
            work towards addressing water scarcity issues in rural India and promoting traditional water conservation
            practices.
        </div>
    </div>

    <div class="dynamicSuccessStorySections" *ngIf="nameOfThePerson == 'APJ Abdul kalam'">
        <div class="col-12 briefSuccessStoryPersonName">
            APJ Abdul kalam
        </div>
        <div class="col-12 briefSuccessStoryPersonPhoto">
            <img class="briefSuccessStoryPersonImageTag" src="../../assets/images/successStories/APJ Abdul Kalam.jpg"
                alt="Success person Image">
        </div>
        <div class="col-12 briefSuccessStoryPersonArticle">
            Dr. APJ Abdul Kalam was the 11th President of India, serving from 2002 to 2007. Born on October 15, 1931, in
            Rameswaram, Tamil Nadu, he was the son of a boat owner and had a humble childhood. Despite facing financial
            difficulties, Dr. Kalam had a strong desire to learn and worked hard to pursue his education. He graduated
            with a degree in Aerospace Engineering from the Madras Institute of Technology and went on to work as a
            scientist at the Defense Research and Development Organization (DRDO) and the Indian Space Research
            Organization (ISRO). <br> <br>

            Dr. Kalam made significant contributions to India's space and defense programs, including the successful
            launch of the Rohini satellite and the development of the indigenous SLV-III rocket. He was also known for
            his role in India's 1998 nuclear tests, which established the country as a nuclear power. <br> <br>

            Despite his achievements in the field of science and technology, Dr. Kalam was also known for his humility
            and his commitment to education and empowering the youth of India. He was known as the "People's President"
            and the "Missile Man of India," and his sudden death in 2015 was mourned by people across India and around
            the world.
        </div>
    </div>

    <div class="dynamicSuccessStorySections" *ngIf="nameOfThePerson == 'K. Kamaraj'">
        <div class="col-12 briefSuccessStoryPersonName">
            K. Kamaraj
        </div>
        <div class="col-12 briefSuccessStoryPersonPhoto">
            <img class="briefSuccessStoryPersonImageTag" src="../../assets/images/successStories/K. Kamaraj.jpeg"
                alt="Success person Image">
        </div>
        <div class="col-12 briefSuccessStoryPersonArticle">
            K. Kamaraj was an Indian politician who served as the Chief Minister of Tamil Nadu from 1954 to 1963. He was
            born on July 15, 1903, in Virudhunagar, Tamil Nadu, and grew up in a rural environment. Kamaraj was actively
            involved in the Indian independence movement from a young age and was imprisoned several times for his
            political activities. <br> <br>

            After India gained independence, Kamaraj became a prominent political leader in Tamil Nadu and was
            instrumental in the formation of the state's first non-Congress government in 1967. He is best known for his
            role in the "Kamaraj Plan," which aimed to improve education and economic opportunities for the rural poor.
            Under this plan, he encouraged Congress Party members to resign from their positions and work for the
            upliftment of the rural masses. <br> <br>

            Kamaraj was a charismatic leader who was highly respected for his simplicity, honesty, and dedication to
            public service. He was also known for his commitment to secularism and was instrumental in the formation of
            several social and cultural organizations in Tamil Nadu. Despite his political achievements, Kamaraj
            remained a humble and down-to-earth individual who was beloved by the people of Tamil Nadu. He passed away
            on October 2, 1975, but his legacy continues to inspire future generations of political leaders in India.
        </div>
    </div>

    <div class="dynamicSuccessStorySections" *ngIf="nameOfThePerson == 'MS Dhoni'">
        <div class="col-12 briefSuccessStoryPersonName">
            M S Dhoni
        </div>
        <div class="col-12 briefSuccessStoryPersonPhoto">
            <img class="briefSuccessStoryPersonImageTag" src="../../assets/images/successStories/M S Dhoni.jpg"
                alt="Success person Image">
        </div>
        <div class="col-12 briefSuccessStoryPersonArticle">
            Mahendra Singh Dhoni, commonly known as MS Dhoni, is a former Indian cricketer who captained the Indian
            national team from 2007 to 2016. He was born on July 7, 1981, in Ranchi, Jharkhand, and grew up in a small
            village in the region. Dhoni showed an early interest in cricket and began playing the sport with a tennis
            ball at a young age. Despite facing financial difficulties and limited resources, he persevered and worked
            hard to pursue his passion for the sport. <br> <br>

            Dhoni made his debut for the Indian cricket team in December 2004 and quickly established himself as one of
            the best wicketkeepers in the world. He captained the Indian team to several notable victories, including
            the 2007 ICC World Twenty20, the 2010 and 2016 Asia Cups, and the 2011 ICC Cricket World Cup. Under his
            leadership, India became one of the strongest teams in international cricket, and Dhoni is widely regarded
            as one of the greatest captains in the history of the sport. <br> <br>

            Off the field, Dhoni is known for his calm demeanor and his ability to handle pressure. He has also been a
            successful businessman and has endorsed several products over the years. Despite his retirement from
            international cricket in August 2020, Dhoni remains a popular and respected figure in India and around the
            world.
        </div>
    </div>

    <div class="dynamicSuccessStorySections" *ngIf="nameOfThePerson == 'T. Natarajan'">
        <div class="col-12 briefSuccessStoryPersonName">
            T. Natarajan
        </div>
        <div class="col-12 briefSuccessStoryPersonPhoto">
            <img class="briefSuccessStoryPersonImageTag" src="../../assets/images/successStories/tNatarajan.jpg"
                alt="Success person Image">
        </div>
        <div class="col-12 briefSuccessStoryPersonArticle">
            Thangarasu Natarajan, commonly known as T. Natarajan, is an Indian cricketer who gained prominence for his
            left-arm medium-fast bowling. Born on May 27, 1991, in Salem, Tamil Nadu, he hails from a humble background.
            His father, S. Thangarasu, was a weaver working on a powerloom, and his mother runs a fast-food stall.
            Natarajan is the eldest of five children. <br> <br>

            Natarajan has earned a reputation for his exceptional ability to consistently deliver yorkers, particularly
            in the crucial death overs of limited-overs matches. He possesses a repertoire of deceptive slower balls and
            swing variations that make him a challenging proposition for batsmen to contend with. Apart from his
            on-field skills, Natarajan's unassuming nature and strong work ethic have also won him praise from teammates
            and fans alike.
            <br> <br>

            In a historic moment, Natarajan etched his name in the annals of cricket history during the Brisbane Test
            against Australia. He achieved the remarkable feat of becoming the first Indian cricketer to make his
            international debut across all three formats (Test, One Day International, and Twenty20 International) on a
            single tour. His performances during this tour garnered widespread acclaim, drawing praise from notable
            former Indian cricketers such as Virender Sehwag and VVS Laxman for his sensational debut across all three
            formats for the Indian cricket team.
        </div>
    </div>

    <div class="dynamicSuccessStorySections" *ngIf="nameOfThePerson == 'N.R. Narayana Murthy'">
        <div class="col-12 briefSuccessStoryPersonName">
            N.R. Narayana Murthy
        </div>
        <div class="col-12 briefSuccessStoryPersonPhoto">
            <img class="briefSuccessStoryPersonImageTag"
                src="../../assets/images/successStories/N. R. Narayana Murthy.jpg" alt="Success person Image">
        </div>
        <div class="col-12 briefSuccessStoryPersonArticle">
            N. R. Narayana Murthy is an Indian entrepreneur and co-founder of Infosys, one of India's largest and most
            successful software companies. Born on August 20, 1946, in Sidlaghatta, Karnataka, he had a difficult
            childhood as his family faced financial difficulties. Despite these challenges, Narayana Murthy was
            determined to succeed and worked hard to pursue his education. <br> <br>

            After completing his engineering degree, Narayana Murthy worked at several companies in India and abroad
            before co-founding Infosys in 1981. Along with his co-founders, he set out to build a company that would
            provide high-quality software services to clients around the world. Under his leadership, Infosys grew to
            become one of India's largest and most successful software companies, with clients in over 50 countries and
            a workforce of over 200,000 employees. <br> <br>

            Narayana Murthy is widely regarded as one of the most influential figures in the Indian technology industry.
            He is known for his strong commitment to corporate governance, transparency, and ethical business practices.
            He is also known for his philanthropic activities and his support for education and healthcare initiatives
            in India. Despite his success, Narayana Murthy remains a humble and unassuming individual who is deeply
            committed to making a positive impact in the lives of people in India and around the world.
        </div>
    </div>
</div>