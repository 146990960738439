import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-stories',
  templateUrl: './success-stories.component.html',
  styleUrls: ['./success-stories.component.css']
})
export class SuccessStoriesComponent implements OnInit {

  spinner: boolean = true;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.spinner = false;
  }

  storePersonNameInLocalStorage(data: any) {
    localStorage.setItem('personNameForArticle', data);
    this.router.navigate(['briefArticles']);
  }
}
