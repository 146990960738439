export class cvUpload {
    cvKey! : string;
    cvFileName! : string;
    cvFileUrl! : string;
    cvFile!: File;
    generatedId! : string;
    cvID! : string;

    constructor(file: File){
        this.cvFile = file;
    }
}