import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { HeaderService } from '../service/header.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  newsLetterSuccessAlert: boolean = false;

  upToDate: boolean = false

  constructor(private fb: FormBuilder, private db: AngularFireDatabase, private headerService: HeaderService) { }

  signup_for_newsletter_form : FormGroup = this.fb.group({
    signupForNewsletterEmail : ['',[Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    signupForNewsletterCheckbox : ['', [Validators.required]]
  })

  get signupForNewsletterEmail() {
    return this.signup_for_newsletter_form.get('signupForNewsletterEmail')
  }

  get signupForNewsletterCheckbox() {
    return this.signup_for_newsletter_form.get('signupForNewsletterCheckbox')
  }

  ngOnInit(): void {
  }

  signUpForNewsLetterFunction() {
    var userData = this.db.database.ref('RY').child('requestForNewsLetter').child(`${Date.now()}`);
    userData.set({
      'newsLetterRequestedEmail' : this.signup_for_newsletter_form.controls['signupForNewsletterEmail'].value,
      'timeStamp' :  `${new Date()}`
    })
    window.scroll({top:0, behavior: 'smooth'});
    setTimeout(() => {
      this.headerService.sendEvent();
    }, 1000)
    this.signup_for_newsletter_form.reset();
  }

  checkUpToDate() {
    if(this.upToDate == false) {
      this.upToDate = true;
    } else {
      this.upToDate = false;
    }
  }

  checkSubmitBtn() {
    if(this.upToDate == true && this.signup_for_newsletter_form.valid) {
      return false
    } else {
      return true
    }
  }
}
