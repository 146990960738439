<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container surveyPhase3MainBox animate__animated animate__fadeInDown">
    <!-- About the survey phase 2 ------------------------------->
    <div class="phase3ContentMainBox">
    <!-- Phase 2 Heading Box -------------------------------->
        <div class="phase3HeadingBox">
            <div class="phase3Heading">{{'phase3.title' | translate}}</div>
            <div class="phase3SubHeading">{{'phase3.noOfPhase' | translate}}</div>
        </div>
    <!-- Choose language Box ------------------------------->
        <!-- <div class="chooseSurveyLangBox">
            <div class="chooseLangHeading">Choose survey language:</div>
            <div class="selectLang">
                <select class="chooseLang" #selectedLang (change)="switchLang(selectedLang.value)" style="pointer-events: none;">
                    <option *ngFor="let language of languages" [value]="language.code" [selected]="language.code === translate.currentLang">{{language.name}}</option>
                </select>
            </div>
        </div> -->
    <!-- Form group phase 2 ------------------------------->
        <form [formGroup]="aboutSurveyPhase3Form">
        <!-- 1st question --------------------------------->
            <div class="afterClgMainBox" *ngFor="let question3 of 'phase3.qust' | translate">
                <div class="afterClgHeading">{{question3.qustTitle}}</div>
                <div *ngIf="question3.inputType == 'radio'">
                    <div class="afterClgBox" *ngFor="let opt of question3.options">
                        <label class="afterClgContainer1">{{opt}}
                            <input type="radio" name="{{question3.formControlName}}" formControlName="{{question3.formControlName}}" value="{{opt}}">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div *ngIf="question3.inputType== 'radioAndFile'">
                    <label for="iHaveSplSkill" class="specialTalentContainer1">{{'phase3.specialSkillsYes' | translate}}
                        <input type="radio" name="specialTalent" formControlName="specialTalent" id="iHaveSplSkill" (click)="openSplTalent()">
                        <span class="checkmark"></span>
                    </label>
                    <div class="selectFileInputBox" *ngIf="this.openSpecialTalentInput == true">
                        <input type="file" class="selectFileInput" id="splTalentInput" (change)="specailTalentFile($event)">
                        <label [ngStyle]="{'visibility': this.selectSplTalentDoc ? 'visible': 'hidden'}" for="splTalentInput" class="markSheetLabel">Select File</label>
                        <img [ngStyle]="{'visibility': this.selectSplTalentDoc ? 'visible': 'hidden'}" src="\assets\img\upload_icon.png" class="markSheetImg" alt="">
                    </div>
                    <div class="specialTalentBox">
                        <label for="iDontHaveSplSkill" class="specialTalentContainer1">{{'phase3.specialSkillsNo' | translate}}
                            <input type="radio" name="specialTalent" formControlName="specialTalent" id="iDontHaveSplSkill" value="I don’t have any special skills" (click)="changeSplTalent()">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>            
        <!-- Next Btn box -------------------------->
            <div class="phase3NextBtnBox">
                <button class="phase3NextBtn" [disabled]="this.checkFormValid()" (click)="submitPhase3()">Next</button>
            </div>
        </form>
    </div>
</div>