<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container becomeVolunteerContainerSeperatePage">
    <div class="row">
        <div class="col-12 becomeVolunteerHeadTextSeperatePage">
            Become a Volunteer
        </div>
        <!-- Make a differnece -->
        <div class="makeADiff">Make a Difference</div>
        <div class="makeDiffText">
            Do you have a passion for empowering rural youth? Are you willing to invest some of your valuable time to
            make a lasting impact on the Rural Youth of India? If so, join us as a volunteer!
        </div>
        <!-- the role of volunteers -->
        <div class="roleOfVolunteer">The Role of Volunteers</div>
        <div class="roleOfVolunteersText">
            Volunteers are the heartbeat of our community. They bring unique backgrounds and skills but share a love for
            cultural engagement and a desire to create positive change.
        </div>
        <!-- our approach -->
        <div class="approach">Our Approach</div>
        <div class="approachText">
            We assist volunteers in discovering abilities and passions that can be channeled into our community,
            ensuring a fulfilling and lasting experience for all involved.
        </div>
        <!-- our approach -->
        <div class="oppAvail">Opportunities Available</div>
        <div class="oppAvailText">
            We're seeking long-term volunteers (6 months to 2 years preferred) to teach English or help identify and
            support rural youth in need.
        </div>
        <!-- our approach -->
        <div class="apply">How to Apply</div>
        <div class="applyText">
            Ready to make a difference? Send your request to become a volunteer to our official email address:
            <span>admin@ruralyouth.in.</span>
        </div>
        <!-- Join us -->
        <div class="joinUsText">
            Join us in this exciting journey to redefine the future for Rural Youth in India. Your time, skills, and
            enthusiasm can have a profound impact. Let's build a better future together!”
        </div>
        <!-- <div class="row applyVolunteerBtnContainerSeperatePage">
            <button class="applyVolunteerBtnSeperatePage" type="button">Apply for volunteer</button>
        </div> -->
    </div>
</div>