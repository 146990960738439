<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container successStoriesMainContainer">
    <div class="headingForRoughTextForSuccessStoriesInRY">
        Celebrating Rural India's Youth: Stories of Inspiration
    </div>
    <!-- unlocking potential -->
    <div class="unlockPotentialHeadText">Unlocking Potential</div>
    <div class="unlockPotentialText">
        Rural India's youth are achieving greatness despite challenges like limited resources. Entrepreneurs, artists,
        innovators, activists - they're making their mark in various fields.
    </div>
    <!-- Inspiring Success Stories -->
    <div class="inspiringSuccessStoriesHeadText">Inspiring Success Stories</div>
    <div class="inspriningContText">
        Our page showcases concise success stories, each with a photo and brief description. These tales capture the
        unique accomplishments and determination of rural India's young generation.
    </div>
    <!-- Join Us in Celebration -->
    <div class="joinUsCelebHeadText">Join Us in Celebration</div>
    <div class="joinUsCelebText">
        Explore our collection of success stories and be inspired by the triumphs of rural India's youth. Together, we
        can foster the growth of the next generation of leaders and innovators.
    </div>
    <div class="imageLoopContainer">
        <div class="row imageContainerOfGallery">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 photoGalleryImageContainer">
                <img src="../../assets/images/successStories/arunachalam muruganandam.jpg" alt="GalleryImage"
                    class="w-75 imageOfImageGalleryRY">
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 p-3 imageGalleryImageMetaData">
                <div class="imageHeadingInGalleryOfRY">
                    Arunachalam Muruganandam
                </div>
                <div class="briefContentAboutImage">
                    Indian social entrepreneur from Coimbatore, Tamil Nadu. He is best known for his work in the field
                    of women's health and for revolutionizing menstrual hygiene in rural India. Born in a poor family.
                    Muruganantham did not have access to proper education and instead, started working as a welder in his early twenties.
                    <span class="readmoreTextForArticle" (click)="storePersonNameInLocalStorage('Arunachalam Muruganandam')">Read more...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="imageLoopContainer">
        <div class="row imageContainerOfGallery">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 photoGalleryImageContainer">
                <img src="../../assets/images/successStories/rajendra singh.jpg" alt="GalleryImage"
                    class="w-75 imageOfImageGalleryRY">
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 p-3 imageGalleryImageMetaData">
                <div class="imageHeadingInGalleryOfRY">
                    Rajendra Singh
                </div>
                <div class="briefContentAboutImage">
                    "Waterman of India," is an Indian environmentalist and water conservationist who has dedicated his
                    life to addressing water scarcity issues in rural India. Born in 1959 in the state of Rajasthan. 
                    Singh had limited formal education but was deeply inspired by traditional Indian water conservation practices.
                    In the 1980s, he founded the
                    <span class="readmoreTextForArticle" (click)="storePersonNameInLocalStorage('Rajendra Singh')">Read more...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="imageLoopContainer">
        <div class="row imageContainerOfGallery">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 photoGalleryImageContainer">
                <img src="../../assets/images/successStories/APJ Abdul Kalam.jpg" alt="GalleryImage"
                    class="w-75 imageOfImageGalleryRY">
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 p-3 imageGalleryImageMetaData">
                <div class="imageHeadingInGalleryOfRY">
                    APJ Abdul kalam
                </div>
                <div class="briefContentAboutImage">
                    Kalam was the 11th President of India, serving from 2002 to 2007. Born on October 15, 1931, in
                    Rameswaram, Tamil Nadu, he was the son of a boat owner and had a humble childhood.
                    Despite facing financial difficulties, Dr. Kalam had a strong desire to learn and worked hard to pursue his education.
                    He graduated with a degree in Aerospace
                    <span class="readmoreTextForArticle" (click)="storePersonNameInLocalStorage('APJ Abdul kalam')">Read more...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="imageLoopContainer">
        <div class="row imageContainerOfGallery">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 photoGalleryImageContainer">
                <img src="../../assets/images/successStories/K. Kamaraj.jpeg" alt="GalleryImage"
                    class="w-75 imageOfImageGalleryRY">
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 p-3 imageGalleryImageMetaData">
                <div class="imageHeadingInGalleryOfRY">
                    K. Kamaraj
                </div>
                <div class="briefContentAboutImage">
                    K. Kamaraj was an Indian politician who served as the Chief Minister of Tamil Nadu from 1954 to
                    1963. He was born on July 15, 1903, in Virudhunagar, Tamil Nadu, and grew up in a rural environment.
                    Kamaraj was actively involved in the Indian independence movement from a young age and was imprisoned several times for his political activities.
                    <span class="readmoreTextForArticle" (click)="storePersonNameInLocalStorage('K. Kamaraj')">Read more...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="imageLoopContainer">
        <div class="row imageContainerOfGallery">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 photoGalleryImageContainer">
                <img src="../../assets/images/successStories/M S Dhoni.jpg" alt="GalleryImage"
                    class="w-75 imageOfImageGalleryRY">
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 p-3 imageGalleryImageMetaData">
                <div class="imageHeadingInGalleryOfRY">
                    MS Dhoni
                </div>
                <div class="briefContentAboutImage">
                    Mahendra Singh Dhoni, commonly known as MS Dhoni, is a former Indian cricketer who captained the
                    Indian national team from 2007 to 2016. He was born on July 7, 1981, in Ranchi, Jharkhand, and grew
                    up in a small village in the region. Dhoni showed an early interest in cricket and began playing the sport 
                    with a tennis ball at a young age.
                    <span class="readmoreTextForArticle" (click)="storePersonNameInLocalStorage('MS Dhoni')">Read more...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="imageLoopContainer">
        <div class="row imageContainerOfGallery">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 photoGalleryImageContainer">
                <img src="../../assets/images/successStories/tNatarajan.jpg" alt="GalleryImage"
                    class="w-75 imageOfImageGalleryRY">
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 p-3 imageGalleryImageMetaData">
                <div class="imageHeadingInGalleryOfRY">
                    T. Natarajan
                </div>
                <div class="briefContentAboutImage">
                    Thangarasu Natarajan, commonly known as T. Natarajan, is an Indian cricketer who gained prominence
                    for his left-arm medium-fast bowling. Born on May 27, 1991, in Salem, Tamil Nadu, Natarajan's
                    journey to professional cricket is quite inspiring. Natarajan has earned a reputation for his exceptional
                    for his exceptional ability to consistently deliver yorkers
                    <span class="readmoreTextForArticle" (click)="storePersonNameInLocalStorage('T. Natarajan')">Read more...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="imageLoopContainer">
        <div class="row imageContainerOfGallery">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 photoGalleryImageContainer">
                <img src="../../assets/images/successStories/N. R. Narayana Murthy.jpg" alt="GalleryImage"
                    class="w-75 imageOfImageGalleryRY">
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-xs-12 p-3 imageGalleryImageMetaData">
                <div class="imageHeadingInGalleryOfRY">
                    N.R. Narayana Murthy
                </div>
                <div class="briefContentAboutImage">
                    N. R. Narayana Murthy is an Indian entrepreneur and co-founder of Infosys, one of India's largest
                    and most successful software companies. Born on August 20, 1946, in Sidlaghatta, Karnataka, he had a
                    difficult childhood as his family faced financial difficulties. Despite these challenges, Narayana Murthy was 
                    determined to succeed
                    <span class="readmoreTextForArticle" (click)="storePersonNameInLocalStorage('N.R. Narayana Murthy')">Read more...</span>
                </div>
            </div>
        </div>
    </div>
</div>