<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container mainContainerForImageGallery">
    <div class="headerOfRoughTextInImageGallery">
        Empowering Rural India's Youth: A Photo Journey
    </div>
    <div class="bodyContentOfRoughTextInImageGallery">
        Welcome to a photo gallery that resonates with our mission to enrich rural India's youth. Explore the images
        that tell the story of transformative learning, skill development, and empowerment.
    </div>
    <div class="typesOfEventsConductedInRYMainBox">
        <div class="typesOfEventsConductedInRYHeadText">
            Types of events in Rural Youth
        </div>
        <div class="row firstEventColumn">
            <div class="col-12 firstEventTypeHeadText">
                1. Interdisciplinary Tech Summit
            </div>
            <div class="col-12 firstEventTypeBodyText">
                Embark on a Visual Odyssey - Witness the fusion of innovation, technology, and creative minds. Students
                from rural areas dive into discussions, workshops, and experiences that ignite their curiosity.
            </div>
            <div class="firstEventTypeImageCorousel">
                <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                            class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="../../assets/images/imageGallery/Interdisciplinary Tech Summit/photo(1).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>First slide label</h5>
                                <p>Some representative placeholder content for the first slide.</p>
                            </div> -->
                        </div>
                        <div class="carousel-item">
                            <img src="../../assets/images/imageGallery/Interdisciplinary Tech Summit/photo(2).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>Second slide label</h5>
                                <p>Some representative placeholder content for the second slide.</p>
                            </div> -->
                        </div>
                        <div class="carousel-item">
                            <img src="../../assets/images/imageGallery/Interdisciplinary Tech Summit/photo(3).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>Third slide label</h5>
                                <p>Some representative placeholder content for the third slide.</p>
                            </div> -->
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row secondEventColumn">
            <div class="col-12 secondEventTypeHeadText">
                2. Tech Fairs
            </div>
            <div class="col-12 secondEventTypeBodyText">
                Unveiling Ingenuity - Experience the vibrancy of technology through our Tech Fairs. It's a celebration
                of youthful creativity where coding, exhibitions, and tech marvels come alive.
            </div>
            <div class="secondEventTypeImageCorousel">
                <div id="carouselExampleCaptions2" class="carousel slide" data-bs-ride="false">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="0"
                            class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="../../assets/images/imageGallery/Tech Fairs/slide(1).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>First slide label</h5>
                                <p>Some representative placeholder content for the first slide.</p>
                            </div> -->
                        </div>
                        <div class="carousel-item">
                            <img src="../../assets/images/imageGallery/Tech Fairs/slide(2).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>Second slide label</h5>
                                <p>Some representative placeholder content for the second slide.</p>
                            </div> -->
                        </div>
                        <div class="carousel-item">
                            <img src="../../assets/images/imageGallery/Tech Fairs/slide(3).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>Third slide label</h5>
                                <p>Some representative placeholder content for the third slide.</p>
                            </div> -->
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions2"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions2"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row thirdEventColumn">
            <div class="col-12 thirdEventTypeHeadText">
              3. Career Guidance
            </div>
            <div class="col-12 thirdEventTypeBodyText">
                Navigating Success Paths - Explore snapshots of rural students guided by mentors and industry leaders,
                as they chart their professional journey with confidence and enlightenment.
            </div>
            <div class="thirdEventTypeImageCorousel">
                <div id="carouselExampleCaptions3" class="carousel slide" data-bs-ride="false">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions3" data-bs-slide-to="0"
                            class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions3" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions3" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="../../assets/images/imageGallery/Career Guidance/image(1).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>First slide label</h5>
                                <p>Some representative placeholder content for the first slide.</p>
                            </div> -->
                        </div>
                        <div class="carousel-item">
                            <img src="../../assets/images/imageGallery/Career Guidance/image(2).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>Second slide label</h5>
                                <p>Some representative placeholder content for the second slide.</p>
                            </div> -->
                        </div>
                        <div class="carousel-item">
                            <img src="../../assets/images/imageGallery/Career Guidance/image(3).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>Third slide label</h5>
                                <p>Some representative placeholder content for the third slide.</p>
                            </div> -->
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions3"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions3"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row fourthEventColumn">
            <div class="col-12 fourthEventTypeHeadText">
               4. Online Learning
            </div>
            <div class="col-12 fourthEventTypeBodyText">
                Embracing Digital Education - Venture into the world of virtual classrooms and interactive learning that
                enable rural students to harness the power of technology for their growth.
            </div>
            <div class="fourthEventTypeImageCorousel">
                <div id="carouselExampleCaptions4" class="carousel slide" data-bs-ride="false">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions4" data-bs-slide-to="0"
                            class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions4" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions4" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="../../assets/images/imageGallery/Online Learning/item(1).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>First slide label</h5>
                                <p>Some representative placeholder content for the first slide.</p>
                            </div> -->
                        </div>
                        <div class="carousel-item">
                            <img src="../../assets/images/imageGallery/Online Learning/item(2).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>Second slide label</h5>
                                <p>Some representative placeholder content for the second slide.</p>
                            </div> -->
                        </div>
                        <div class="carousel-item">
                            <img src="../../assets/images/imageGallery/Online Learning/item(3).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>Third slide label</h5>
                                <p>Some representative placeholder content for the third slide.</p>
                            </div> -->
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions4"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions4"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row fifthEventColumn">
            <div class="col-12 fifthEventTypeHeadText">
               5. Mentorship Programs
            </div>
            <div class="col-12 fifthEventTypeBodyText">
                Nurturing Tomorrow's Leaders - Walk beside our mentorship initiatives, reflecting wisdom, support, and
                connections that foster the skills and emotional growth of rural youth.
            </div>
            <div class="fifthEventTypeImageCorousel">
                <div id="carouselExampleCaptions5" class="carousel slide" data-bs-ride="false">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions5" data-bs-slide-to="0"
                            class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions5" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions5" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="../../assets/images/imageGallery/Mentorship Programs/gallery(1).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>First slide label</h5>
                                <p>Some representative placeholder content for the first slide.</p>
                            </div> -->
                        </div>
                        <div class="carousel-item">
                            <img src="../../assets/images/imageGallery/Mentorship Programs/gallery(2).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>Second slide label</h5>
                                <p>Some representative placeholder content for the second slide.</p>
                            </div> -->
                        </div>
                        <div class="carousel-item">
                            <img src="../../assets/images/imageGallery/Mentorship Programs/gallery(3).jpg"
                                class="d-block w-100 bootstrapImageCorouselImage" alt="...">
                            <!-- <div class="carousel-caption d-none d-md-block">
                                <h5>Third slide label</h5>
                                <p>Some representative placeholder content for the third slide.</p>
                            </div> -->
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions5"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions5"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="joinUsRyCont">
            Join us in this visual journey that paints a promising future for rural India's youth. Their empowerment is
            our commitment, and their success, our shared dream.
        </div>
        <div class="exploreText">
            <div class="exploreMoreText" routerLink="/About">Explore More |</div>
            <div class="getInvlText" routerLink="/becomeVolunteerPage">Get Involved</div>
        </div>
    </div>
</div>