import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-become-volunteer',
  templateUrl: './become-volunteer.component.html',
  styleUrls: ['./become-volunteer.component.css']
})
export class BecomeVolunteerComponent implements OnInit {

  spinner: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.spinner = false
  }

}
