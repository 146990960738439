import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from '../model/supported-languages';
import { SurveyFormService } from '../survey-form.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-survey-phase4',
  templateUrl: './survey-phase4.component.html',
  styleUrls: ['./survey-phase4.component.css']
})
export class SurveyPhase4Component implements OnInit {

  phase4_survey_form!: FormGroup;
  languages = SUPPORTED_LANGUAGES;
  
  phase4FormGroupData() {
    this.phase4_survey_form = this.formBuilder.group({
      phase4Ques1: ['', [Validators.required, Validators.pattern('^[a-zA-Z][\sa-zA-Z ]*')]],
      phase4Ques2: ['', [Validators.required]],
      phase4Ques3: ['', [Validators.required]]
    })
  }

  spinner: boolean = true;

  constructor(private formBuilder: FormBuilder, private uploadService: SurveyFormService, public translate: TranslateService, private router: Router) { 
    var language = JSON.parse(localStorage.getItem('surveyLanguage')!)
    translate.addLangs(['en', 'ta']);
    if(language) {
      translate.setDefaultLang(language);
    } else {
      translate.setDefaultLang('en')
    }
  }

  ngOnInit(): void {
    this.startSurveyAsNew();
    this.phase4FormGroupData();
    this.spinner = false
    // this.patchValueFromLocal();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  patchValueFromLocal() {
    var phase4Form = JSON.parse(localStorage.getItem('surveyPhase4Form')!);
    if(phase4Form != null) {
      this.phase4_survey_form.patchValue(phase4Form);
    }
  }

  startSurveyAsNew() {
    // Form 1
    var phase1Form = [];
    phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    // Form 2
    var phase2Form = [];
    phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    // Form 3
    var phase3Form = [];
    phase3Form = JSON.parse(localStorage.getItem('surveyPhase3Form')!);
    // Form 4
    var phase4Form = [];
    phase4Form = JSON.parse(localStorage.getItem('surveyPhase4Form')!);
    // Form 5
    var phase5Form = [];
    phase5Form = JSON.parse(localStorage.getItem('surveyPhase5Form')!);

    if (phase1Form == null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['TakeSurvey']) 
    } else if (phase1Form != null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['phase2']) 
    } else if (phase1Form != null && phase2Form != null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['phase3']) 
    } else if (phase1Form != null && phase2Form != null && phase3Form != null && phase4Form == null) {
      this.router.navigate(['phase4']) 
    } else if (phase1Form != null && phase2Form != null && phase4Form != null && phase4Form != null) {
      this.router.navigate(['phase5']) 
    }
  }

  submitPhase4Form(){
    // Form 1
    var phase1Form = [];
    phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    // Form 2
    var phase2Form = [];
    phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    // Form 3
    var phase3Form = [];
    phase3Form = JSON.parse(localStorage.getItem('surveyPhase3Form')!);

    if (phase1Form != null && phase1Form != undefined && phase2Form != null && phase2Form != undefined && phase3Form != null && phase3Form != undefined) {
      this.uploadService.getPhase4Form(this.phase4_survey_form.value);
      this.router.navigate(['phase5']);
    }
    else {
      this.router.navigate(['TakeSurvey']).then(() => {
        alert("Please fill the survey step by step properly !")
      })
    }
  }

  get phase4Ques1(){
    return this.phase4_survey_form.get('phase4Ques1')
  }

  get phase4Ques2(){
    return this.phase4_survey_form.get('phase4Ques2')
  }

  get phase4Ques3(){
    return this.phase4_survey_form.get('phase4Ques3')
  }

  shouldNotBeNumber(nameReg: RegExp){
    return (control: { value: any; }) =>{
      let isNotNumberic = true;
      let name = control.value;
      for(let i =0; i<name.length; i++){
        if(parseInt(name[i]) < 65 || parseInt(name[i]) > 122){
          isNotNumberic = false;
          break;
        }
      }
      return isNotNumberic ? null: { shouldNotBeNumber: { value : control.value} };
    }
  }
  

}
