<div class="footer">
    <div class="container footer_main p-0">
        <div class="row LapResponsive g-0">
            <div class="col-xl-4 col-lg-4 col-md-5 col-sm-5 footer_contact_text">
                <p class="p_tag_defult_style_removing">Contact</p>
                <div class="row">
                    <div class="col-xl-4 footer_address_text">
                        <p class="p_tag_defult_style_removing">
                            Rural Youth of India, <br>
                            2/90, Sarvoy pudur panchayath, <br>
                            Sarvoy post, Attur, Salem, <br>
                            TamilNadu, India 636121.
                        </p>
                    </div>
                </div>
                <div class="row g-0">
                    <div class="col footer_email">
                        <p class="p_tag_defult_style_removing">
                            <a href="mailto:admin@ruralyouth.in" class="emailInFooter" >
                            <img src="../../assets/svg/email_black_24dp (2).svg" alt="email_logo" class="email_logo">
                            admin@ruralyouth.in
                            </a>
                        </p>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col footer_num">
                        <p class="p_tag_defult_style_removing">
                            <img src="../../assets/svg/call_black_24dp (1).svg" alt="email_logo" class="phone_logo">
                            +91 978966469
                        </p>
                    </div>
                </div> -->
            </div>
            <div class="col-6 col-lg col-md col-sm footer_Connectwithus_text">
                <p class="p_tag_defult_style_removing">Connect with us</p>
                <div class="row social_media_logo g-0">
                    <div class="col-1 col-lg-1 col-md-1 col-sm-1">
                        <a href=" https://www.facebook.com/ruralyouthofindia/" target="_blank"><img src="../../assets/svg/facebook.svg" alt="facebookLogo" class="facebookLogo"></a>
                    </div>
                    <div class="col-1 col-lg-1 col-md-1 col-sm-1">
                        <a href="https://twitter.com/ruralyouthofind?t=GInXbBoioFqU53h1MRQCuQ&s=08" target="_blank"><img src="../../assets/svg/twitter.svg" alt="twitterLogo" class="twitterLogo"></a>
                    </div>
                    <div class="col-1 col-lg-1 col-md-1 col-sm-1">
                        <a href="https://www.linkedin.com/company/ruralyouthofindia/" target="_blank"><img src="../../assets/svg/linkedin.svg" alt="linkedinLogo" class="linkedinLogo"></a>
                    </div>
                    <div class="col-1 col-lg-1 col-md-1 col-sm-1">
                        <a href="https://youtube.com/channel/UCBDkZ7ZlO_sU6qT-HxZ4gIQ" target="_blank"><img src="../../assets/svg/youtube.svg" alt="youtubeLogo" class="youtubeLogo"></a>
                    </div>
                    <div class="col-1 col-lg-1 col-md-1 col-sm-1">
                        <a href="https://instagram.com/ruralyouthofindia?igshid=YmMyMTA2M2Y=" target="_blank"><img src="../../assets/svg/instagram.svg" alt="instagramLogo" class="instagramLogo"></a>
                    </div>
                </div>
                <div class="copyRights">© 2023 Rural Youth. All rights reserved</div>
            </div>
            <form class="col-4 SignUpForNewsLetterOption" [formGroup]="signup_for_newsletter_form" method="post">
                <div class="signUpForNewsLetterText">
                    Sign up for our newsletter
                </div>
                <div>
                    <input type="text" [ngClass]="{'notValid': signupForNewsletterEmail?.invalid && (signupForNewsletterEmail?.dirty || signupForNewsletterEmail?.touched)}" class="signUpForNewsLetterInputBox" formControlName="signupForNewsletterEmail" placeholder="Enter Email">
                    <!-- <div *ngIf="signupForNewsletterEmail?.invalid && (signupForNewsletterEmail?.dirty || signupForNewsletterEmail?.touched)">
                        <div *ngIf="signupForNewsletterEmail?.errors?.['required']" class="news-alert-msg">
                            Email should not be empty
                        </div>
                        <div *ngIf="signupForNewsletterEmail?.errors?.['email']" class="news-alert-msg">
                            Enter valid email address
                        </div>
                    </div> -->
                </div>
                <div class="signUpForNewsLetterCheckboxContainer">
                    <input type="checkbox" class="signUpForNewsLetterCheckbox" [checked]="this.upToDate == true" (change)="checkUpToDate()" placeholder="Your email" formControlName="signupForNewsletterCheckbox">
                    <div class="signUpForNewsLetterCheckboxText">Stay up to date with our newsletter</div>
                </div>
                <div>
                    <button class="signUpForNewsLetterSubmitButton" (click)="signUpForNewsLetterFunction()" [disabled]="checkSubmitBtn()">Submit</button>
                </div>
            </form>
        </div>
        <div class="row MobileResponsive g-0">
            <form class="col-4 SignUpForNewsLetterOption" [formGroup]="signup_for_newsletter_form" method="post">
                <div class="signUpForNewsLetterText">
                    Sign up for our newsletter
                </div>
                <div>
                    <input type="text" [ngClass]="{'notValid': signupForNewsletterEmail?.invalid && (signupForNewsletterEmail?.dirty || signupForNewsletterEmail?.touched)}" class="signUpForNewsLetterInputBox" formControlName="signupForNewsletterEmail" placeholder="Enter Email">
                    <!-- <div *ngIf="signupForNewsletterEmail?.invalid && (signupForNewsletterEmail?.dirty || signupForNewsletterEmail?.touched)">
                        <div *ngIf="signupForNewsletterEmail?.errors?.['required']" class="news-alert-msg">
                            Email should not be empty
                        </div>
                        <div *ngIf="signupForNewsletterEmail?.errors?.['email']" class="news-alert-msg">
                            Only valid email address
                        </div>
                    </div> -->
                </div>
                <div class="signUpForNewsLetterCheckboxContainer">
                    <input type="checkbox" class="signUpForNewsLetterCheckbox" placeholder="Your email" formControlName="signupForNewsletterCheckbox">
                    <div class="signUpForNewsLetterCheckboxText">Stay up to date with our newsletter</div>
                </div>
                <div>
                    <button class="signUpForNewsLetterSubmitButton" (click)="signUpForNewsLetterFunction()" [disabled]="!signup_for_newsletter_form.valid">Submit</button>
                </div>
            </form>
            <div class="col-12 footer_Connectwithus_text">
                <p class="p_tag_defult_style_removing">Connect with us</p>
                <div class="row social_media_logo g-0">
                    <div class="col-1 ">
                        <a href=" https://www.facebook.com/ruralyouthofindia/" target="_blank"><img src="../../assets/svg/facebook.svg" alt="facebookLogo" class="facebookLogo"></a>
                    </div>
                    <div class="col-1">
                        <a href="https://twitter.com/ruralyouthofind?t=GInXbBoioFqU53h1MRQCuQ&s=08" target="_blank"><img src="../../assets/svg/twitter.svg" alt="twitterLogo" class="twitterLogo"></a>
                    </div>
                    <div class="col-1">
                        <a href="https://www.linkedin.com/company/ruralyouthofindia/" target="_blank"><img src="../../assets/svg/linkedin.svg" alt="linkedinLogo" class="linkedinLogo"></a>
                    </div>
                    <div class="col-1">
                        <a href="https://youtube.com/channel/UCBDkZ7ZlO_sU6qT-HxZ4gIQ" target="_blank"><img src="../../assets/svg/youtube.svg" alt="youtubeLogo" class="youtubeLogo"></a>
                    </div>
                    <div class="col-1">
                        <a href="https://instagram.com/ruralyouthofindia?igshid=YmMyMTA2M2Y=" target="_blank"><img src="../../assets/svg/instagram.svg" alt="instagramLogo" class="instagramLogo"></a>
                    </div>
                </div>
            </div>
            <div class="col-12 footer_contact_text">
                <p class="p_tag_defult_style_removing">Contact</p>
                <div class="row g-0">
                    <div class="col-12 footer_address_text">
                        <p class="p_tag_defult_style_removing">
                            Rural Youth of India, <br>
                            2/90, Sarvoy pudur panchayath, <br>
                            Sarvoy post, Attur, Salem,
                            TamilNadu, India 636121.
                        </p>
                    </div>
                </div>
        
                <div class="row g-0">
                    <div class="col-12 footer_email">
                        <p class="p_tag_defult_style_removing">
                            <a href="mailto:admin@ruralyouth.in" class="emailInFooter">
                            <img src="../../assets/svg/email_black_24dp (2).svg" alt="email_logo" class="email_logo">
                            admin@ruralyouth.in
                            </a>
                        </p>
                    </div>
                </div>
                <div class="copyRights">© 2023 Rural Youth. All rights reserved</div>
                <!-- <div class="row">
                    <div class="col-12 footer_num">
                        <p class="p_tag_defult_style_removing">
                            <img src="../../assets/svg/call_black_24dp (1).svg" alt="email_logo" class="phone_logo">
                            +91 978966469
                        </p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>