<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container contactUsMain">
    <!-- success message -->
    <div class="contactMsgSuccessMsgBox" id="contactMsgSuccessMsgBoxId">
        <img src="assets\images\greenTick.svg"  style="padding-right: 10px;" class="greenTickImg" alt="">
        Thank you for Contacting Us!
    </div>
    <div class="contactUsHeading">
        Contact Us
    </div>
    <div class="contactHeadingText">
        We're excited to connect with you and explore opportunities for collaboration, feedback, and engagement. Feel
        free to reach out to us through any of the following channels:
    </div>
    <div class="row contactUs_LapResponsice">
        <div class="col-5">
            <div class="contactInfo">
                <h2 class="contactInfoHeading">General Contact Information</h2>
                <div class="contactInfoEmailBox d-flex">
                    <div class="emailIcon"><i class="fa fa-envelope"></i></div>
                    <div class="contactInfoEmail">admin@ruralyouth.in</div>
                </div>
                <!-- <div class="contactInfoPhoneBox d-flex">
                    <div class="phoneIcon"><i class="fa-solid fa-phone"></i></div>
                    <div class="contactInfoPhone">+91 978966469</div>
                </div> -->
                <div class="contactInfoAddressBox d-flex">
                    <div class="locationIcon"><i class="fa fa-location-dot"></i></div>
                    <div class="contactInfoAddress">Rural Youth of India,<br>
                        2/90, Sarvoy pudur panchayath,<br>
                        Sarvoy post, Attur, Salem, <br>
                        TamilNadu, India 636121.
                    </div>
                </div>
            </div>
        </div>
        <div class="verticalLine"></div>
        <div class="col-7" style="width: 400px;">
            <div class="contactForm">
                <form [formGroup]="contact_form_group" method="post">
                    <h2 class="contactFormHeading">Write to us</h2>
                    <div class="contactFormNameBox">
                        <div class="contactFormName">Name</div>
                        <div class="contactFormNameInputBox" [ngClass]="{'errorBorder': contactFormName?.invalid && (contactFormName?.dirty || contactFormName?.touched)}">
                            <input class="contactFormNameInput" type="text" formControlName="contactFormName">
                        </div>
                    </div>
                    <div *ngIf="contactFormName?.invalid && (contactFormName?.dirty || contactFormName?.touched)"
                        class="alertt">
                        <div *ngIf="contactFormName?.errors?.['required']" class="alert-msg">
                            Name should not be empty
                        </div>
                        <div *ngIf="contactFormName?.errors?.['minlength']" class="alert-msg">
                            Name should be minimum 2 character length
                        </div>
                        <!-- <div *ngIf="contactFormName?.errors?.['maxlength']" class="alert-msg">
                            maximum 30 character length
                        </div> -->
                        <div *ngIf="contactFormName?.errors?.['pattern']" class="alert-msg">
                            Name should be only alphabets
                        </div>
                    </div>
                    <div class="contactFormEmailBox">
                        <div class="contactFormEmail">Email address</div>
                        <div class="contactFormEmailInputBox" [ngClass]="{'errorBorder': contactFormEmail?.invalid && (contactFormEmail?.dirty || contactFormEmail?.touched)}">
                            <input class="contactFormEmailInput" type="email" formControlName="contactFormEmail">
                        </div>
                    </div>
                    <div *ngIf="contactFormEmail?.invalid && (contactFormEmail?.dirty || contactFormEmail?.touched)"
                        class="alertt">
                        <div *ngIf="contactFormEmail?.errors?.['required']" class="alert-msg">
                            Email should not be empty
                        </div>
                        <div *ngIf="contactFormEmail?.errors?.['pattern']" class="alert-msg">
                            Enter valid email address
                        </div>
                    </div>
                    <div class="contactFormCmtsBox">
                        <div class="contactFormCmts">Your comments</div>
                        <div class="contactFormCmtsInputBox" [ngClass]="{'errorBorder': contactFormCmts?.invalid && (contactFormCmts?.dirty || contactFormCmts?.touched)}">
                            <textarea class="contactFormCmtsInput" type="text"
                                formControlName="contactFormCmts"></textarea>
                        </div>
                    </div>
                    <div *ngIf="contactFormCmts?.invalid && (contactFormCmts?.dirty || contactFormCmts?.touched)"
                        class="alertt">
                        <div *ngIf="contactFormCmts?.errors?.['required']" class="alert-msg">
                            Comments should not be empty
                        </div>
                        <div *ngIf="contactFormCmts?.errors?.['pattern']" class="alert-msg">
                            Comments should be only alphabets
                        </div>
                    </div>
                    <button class="btn rounded-pill shadow-none contactFormSubmitBtn" (click)="onSubmit()" [disabled]="!contact_form_group.valid" type="submit">
                        Submit
                    </button>
                </form>
                <!-- Successfully saved your Contact details  ------------------------------------>
                <!-- <div class="modal fade" id="saveContactUs" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modalSuccessMain" role="document">
                        <div class="modal-content modalSuccessContent">
                            <div class="modal-body">
                                <img class="successImg" src="\assets\greenTick.svg">
                                <div class="successContent">Thank you for contacting us, your message has gone through!
                                </div>
                                <button data-bs-dismiss="modal" aria-label="Close" aria-hidden="true"
                                    class="closeBtn">Close</button>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row contactUs_MobileResponsice">
        <div class="col-12">
            <div class="contactForm">
                <form [formGroup]="contact_form_group" method="post">
                    <h2 class="contactFormHeading">Write to us</h2>
                    <div class="contactFormNameBox">
                        <div class="contactFormName">Name</div>
                        <div class="contactFormNameInputBox" [ngClass]="{'errorBorder': contactFormName?.invalid && (contactFormName?.dirty || contactFormName?.touched)}">
                            <input class="contactFormNameInput" type="text" formControlName="contactFormName">
                        </div>
                    </div>
                    <div *ngIf="contactFormName?.invalid && (contactFormName?.dirty || contactFormName?.touched)"
                        class="alertt">
                        <div *ngIf="contactFormName?.errors?.['required']" class="alert-msg">
                            Name should not be empty
                        </div>
                        <div *ngIf="contactFormName?.errors?.['minlength']" class="alert-msg">
                            Name should be minimum 2 character length
                        </div>
                        <div *ngIf="contactFormName?.errors?.['pattern']" class="alert-msg">
                            Name should be only alphabets
                        </div>
                    </div>
                    <div class="contactFormEmailBox">
                        <div class="contactFormEmail">Email address</div>
                        <div class="contactFormEmailInputBox" [ngClass]="{'errorBorder': contactFormEmail?.invalid && (contactFormEmail?.dirty || contactFormEmail?.touched)}">
                            <input class="contactFormEmailInput" type="email" formControlName="contactFormEmail">
                        </div>
                    </div>
                    <div *ngIf="contactFormEmail?.invalid && (contactFormEmail?.dirty || contactFormEmail?.touched)"
                        class="alertt">
                        <div *ngIf="contactFormEmail?.errors?.['required']" class="alert-msg">
                            Email should not be empty
                        </div>
                        <div *ngIf="contactFormEmail?.errors?.['pattern']" class="alert-msg">
                            Email should be valid
                        </div>
                    </div>
                    <div class="contactFormCmtsBox">
                        <div class="contactFormCmts">Your comments</div>
                        <div class="contactFormCmtsInputBox" [ngClass]="{'errorBorder': contactFormCmts?.invalid && (contactFormCmts?.dirty || contactFormCmts?.touched)}">
                            <textarea class="contactFormCmtsInput" type="text"
                                formControlName="contactFormCmts"></textarea>
                        </div>
                    </div>
                    <div *ngIf="contactFormCmts?.invalid && (contactFormCmts?.dirty || contactFormCmts?.touched)"
                        class="alertt">
                        <div *ngIf="contactFormCmts?.errors?.['required']" class="alert-msg">
                            Comments should not be empty
                        </div>
                        <div *ngIf="contactFormCmts?.errors?.['pattern']" class="alert-msg">
                            Comments should be only alphabets
                        </div>
                    </div>
                    <button class="btn rounded-pill shadow-none contactFormSubmitBtn contactUs_LapResponsice" [disabled]="!contact_form_group.valid"
                        (click)="onSubmit()" type="submit">Submit</button>

                    <button class="btn rounded-pill shadow-none contactFormSubmitBtn contactUs_MobileResponsice"
                        [disabled]="!contact_form_group.valid" (click)="onSubmit()" type="submit">Submit</button>
                </form>
            </div>
        </div>
        <div class="col-12">
            <div class="contactInfo">
                <h2 class="contactInfoHeading">General Contact Information</h2>
                <div class="contactInfoEmailBox d-flex">
                    <div class="emailIcon"><i class="fa fa-envelope"></i></div>
                    <div class="contactInfoEmail">admin@ruralyouth.in</div>
                </div>
                <!-- <div class="contactInfoPhoneBox d-flex">
                    <div class="phoneIcon"><i class="fa-solid fa-phone"></i></div>
                    <div class="contactInfoPhone">+91 978966469</div>
                </div> -->
                <div class="contactInfoAddressBox d-flex">
                    <div class="locationIcon"><i class="fa fa-location-dot"></i></div>
                    <div class="contactInfoAddress">Rural Youth of India,<br>
                        2/90, Sarvoy pudur panchayath,<br>
                        Sarvoy post, Attur, Salem, <br>
                        TamilNadu, India 636121.
                    </div>
                </div>
            </div>
        </div>
        <!-- Successfully saved your Contact details  ------------------------------------>
        <!-- <div class="modal fade" id="saveContactUs" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modalSuccessMain" role="document">
                <div class="modal-content modalSuccessContent">
                    <div class="modal-body">
                        <img class="successImg" src="\assets\greenTick.svg">
                        <div class="successContent">Thank you for contacting us, your message has gone through!
                        </div>
                        <button data-bs-dismiss="modal" aria-label="Close" aria-hidden="true"
                            class="closeBtn">Close</button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <div class="row">
        <div class="col-12 mapcontainer">
            <div class="mapHead">Location Map:</div>
            <div class="mapContent">
                To locate our organization, use the interactive map below. It provides clear directions to our office, making it easier for you to connect with us.
            </div>
            <div class="map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.5206920151572!2d78.6949927141228!3d11.586178646894334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bab770df1c54371%3A0x5bde6efc1b2a1d10!2sRural%20Youth%20of%20India!5e0!3m2!1sen!2sin!4v1651832422860!5m2!1sen!2sin"
                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</div>

<!-- Successfully Submitted your thesis ------------------>
<!-- <div class="modal fade" id="MobilesaveContactUs" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered ContactUsmodalSuccessMain" role="document">
        <div class="modal-content ContactUsmodalSuccessContent">
            <div class="modal-body">
                <img class="successImg" src="\assets\greenTick.svg">
                <div class="successContent">Thank you for contacting us, your message has gone through!
                </div>
                <button data-bs-dismiss="modal" aria-label="Close" aria-hidden="true" class="closeBtn">Close</button>
            </div>
        </div>
    </div>
</div> -->