export class specailTalentFileUpload {
    splTalentKey! : string;
    proofFileName! : string;
    proofFileUrl! : string;
    splProofFile!: File;
    generatedId! : string;
    splProofID! : string;

    constructor(file: File){
        this.splProofFile = file;
    }
}