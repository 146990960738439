<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container phase5_container_main">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col">
                    <div class="phase5_header_main">{{'phase5.title' | translate}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="phase5_header_body_content">{{'phase5.noOfPhase' | translate}}</div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col">
                    <div class="chooseSurveyLangBox">
                        <div class="chooseLangHeading">Choose survey language:</div>
                        <div class="selectLang">
                            <select class="chooseLang" #selectedLang (change)="switchLang(selectedLang.value)" style="pointer-events: none;">
                                <option *ngFor="let language of languages" [value]="language.code" [selected]="language.code === translate.currentLang">{{language.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- Qusetion Section -->
            <form [formGroup]="phase5_survey_form">
                <div *ngFor="let question of 'phase5.qust' | translate">
                    <div class="row">
                        <div class="col">
                            <div class="phase5_ques1_text">{{question.qustTitle}}</div>
                        </div>
                    </div>
                    <!-- radio button type -->
                    <div class="row">
                        <div class="col">
                            <div *ngFor="let opt of question.options; let i = index">
                                <label *ngIf="question.inputType == 'radio'" class="phase5_educationContainer1">{{opt}}
                                    <input type="radio" value="{{opt}}" name="{{question.formControlName}}" formControlName="{{question.formControlName}}">
                                    <span class="phase5_checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- input type -->
                    <div *ngIf="question.inputType == 'input'" class="row">
                        <div class="col">
                            <textarea name="{{question.formControlName}}" formControlName="{{question.formControlName}}" placeholder="Please write your answer here" class="phase5_ques3_input_text" rows="6"></textarea>
                        </div>
                    </div>
                    <div *ngIf="question.formControlName== 'phase5Ques3'">
                        <div *ngIf="phase5Ques3?.invalid && (phase5Ques3?.dirty || phase5Ques3?.touched)" class="errorsBox">
                            <div *ngIf="phase5Ques3?.errors?.['required']" class="errorMsg">
                                Should not be null or empty
                            </div>
                            <div *ngIf="phase5Ques3?.errors?.['pattern']" class="errorMsg">
                                Should be alphabets
                            </div>
                        </div>
                    </div>
                    <div *ngIf="question.formControlName== 'phase5Ques4'">
                        <div *ngIf="phase5Ques4?.invalid && (phase5Ques4?.dirty || phase5Ques4?.touched)" class="errorsBox">
                            <div *ngIf="phase5Ques4?.errors?.['required']" class="errorMsg">
                                Should not be null or empty
                            </div>
                            <div *ngIf="phase5Ques4?.errors?.['pattern']" class="errorMsg">
                                Should be alphabets
                            </div>
                        </div>
                    </div>
                    <!-- file type -->
                    <div *ngIf="question.inputType == 'file'" class="row">
                        <div class="col">
                            <div class="phase5_selectFileInputBox">
                                <input type="file" class="phase5_selectFileInput" formControlName="phase5Ques8" id="selectCVInput" accept=".doc, .docx, .pdf" (change)="selectCVFile($event)">
                                <label [ngStyle]="{'visibility': this.selectCVLabel ? 'visible': 'hidden'}" for="selectCVInput" class="phase5_CV_Label">Select File</label>
                                <img [ngStyle]="{'visibility': this.selectCVLabel ? 'visible': 'hidden'}" src="\assets\img\upload_icon.png" class="phase5_CV_upload_img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!-- Previous & next button -->
            <div class="row">
                <div class="col phase5SubmitBtnBox">
                    <button class="phase5_next_btn" data-bs-toggle="modal" data-bs-target="#quoteForm" [disabled]="this.checkFormIsValid()" (click)="submitPhase5Form()">Submit</button>
                </div>
                <!-- <div class="col phase5SubmitBtnBox" *ngIf="!switchButtonFunction()">
                    <button class="phase5_next_btn" [disabled]="!phase5_survey_form.valid" (click)="dummyButtonForAlert()">Submit</button>
                </div> -->
            </div>
            <!-- Successfully Submitted your thesis ------------------>
            <div class="modal fade" id="quoteForm" tabindex="-1" data-bs-backdrop="static" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modalSuccessMain" role="document">
                    <div class="modal-content modalSuccessContent">
                        <div class="modal-body">
                            <div class="imgAndCloseBox">
                                <img class="successImg" src="assets\img\thumbsUpGreen.png">
                                <button data-bs-dismiss="modal" aria-label="Close" aria-hidden="true" class="closeBtn">
                                    <img src="assets\img\closeModel.svg" (click)="clearForm()" class="closeImg" alt="">
                                </button>
                            </div>
                            <div class="successContent">
                                <div class="doneContent">Thank you for completing the survey!</div>
                                <div class="smsContent">Your survey has been completed in {{this.overAllTime}}.</div> 
                                <div class="smsContent">You'll receive a heartfelt thank-you email soon.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>