import { Injectable } from '@angular/core';
import { finalize, Observable, Subject } from 'rxjs';
import { specailTalentFileUpload } from './model/specailTalent';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { cvUpload } from './model/cvUpload';
@Injectable({
  providedIn: 'root'
})
export class SurveyFormService {

  private splFilePath = '/userDetails/specailTalentDocs';
  private cvFilePath = '/userDetails/cvDocs';

  splTalentData: any;
  cvFileData: any;
  splTalentLocalData: any;
  cvLocalData: any;

  // Local Form Data -------------------------
  phase1Form: any;
  phase2Form: any;
  phase3Form: any;
  phase4Form: any;
  phase5Form: any;

  dateOfSubmission: any;

  private progressValue: number = 100;

  private subjectOfTimer = new Subject<any>();

  constructor(private storage: AngularFireStorage, private db: AngularFireDatabase) { }

  // Phase 1 form details ---------------------------------------------
  getPhase1Form(form1: any){
    localStorage.setItem('surveyPhase1Form', JSON.stringify(form1));
  }

  // Phase 2 form details ------------------------------------------------
  getPhase2Form(form2: any){
    localStorage.setItem('surveyPhase2Form', JSON.stringify(form2));
  }

  // Phase 3 form details -------------------
  getPhase3Form(form3: any){
    localStorage.setItem('surveyPhase3Form', JSON.stringify(form3));
  }

  // Phase 4 form details ------------------
  getPhase4Form(form4: any){
    localStorage.setItem('surveyPhase4Form', JSON.stringify(form4));
  }

  // Phase 5 form details -----------------
  getPhase5Form(form5: any){
    localStorage.setItem('surveyPhase5Form', JSON.stringify(form5));
    this.sendFormDetails();
  }

  // special talent proof document --------------------------------
  pushSpecialTalentFileToStorage(fileUpload: specailTalentFileUpload): Observable<number | undefined>{
    const filePath = `${this.splFilePath}/${fileUpload.splProofFile.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.splProofFile);

    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.proofFileUrl = downloadURL;
          fileUpload.proofFileName = fileUpload.splProofFile.name;
          this.splTalentData = fileUpload;
          this.saveFileData();
        });
      })
    ).subscribe();
    return uploadTask.percentageChanges();
  }

  saveFileData(){
    localStorage.setItem('specailTalentDocument', JSON.stringify(this.splTalentData));
    this.getSplTalentLocalData();
  }

  getSplTalentLocalData(){
    this.splTalentLocalData = [];
    this.splTalentLocalData = JSON.parse(localStorage.getItem('specailTalentDocument')!);
    return this.splTalentLocalData;
  }

  // CV file upload --------------------------------------------------
  pushCVFileToStorage(cv: cvUpload): Observable<number | undefined>{
    const filePath = `${this.cvFilePath}/${cv.cvFile.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, cv.cvFile);

    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downLoadUrl => {
          cv.cvFileUrl = downLoadUrl;
          cv.cvFileName = cv.cvFile.name;
          this.cvFileData = cv;
          this.saveCVFileData();
        });
      })
    ).subscribe();
    return uploadTask.percentageChanges();
  }

  saveCVFileData(){
    localStorage.setItem('cvDocument', JSON.stringify(this.cvFileData));
    this.getCVLocalData();
  }

  getCVLocalData(){
    this.cvLocalData= [];
    this.cvLocalData = JSON.parse(localStorage.getItem('cvDocument')!);
    return this.cvFileData;
  }

  // Submit survey form ---------------------------------
  sendFormDetails(){
    // Form 1
    this.phase1Form = [];
    this.phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    // Form 2
    this.phase2Form = [];
    this.phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    // Form 3
    this.phase3Form = [];
    this.phase3Form = JSON.parse(localStorage.getItem('surveyPhase3Form')!);
    // Form 4
    this.phase4Form = [];
    this.phase4Form = JSON.parse(localStorage.getItem('surveyPhase4Form')!);
    // Form 5
    this.phase5Form = [];
    this.phase5Form = JSON.parse(localStorage.getItem('surveyPhase5Form')!);

    this.date();
    this.submitSurveyFormDetailsOnDB(this.phase1Form, this.phase2Form, this.phase3Form, this.phase4Form, this.phase5Form);
  }
  
  date(){
    let date = new Date();
    let fullDate = date.getDate();
    let year = date.getFullYear().toString();
    let month = date.toLocaleString('en-us', { month: 'long'});
    this.dateOfSubmission = fullDate + ' '+month+' '+year;
    return fullDate+ month+ year
  }

  submitSurveyFormDetailsOnDB(form1: any, form2: any, form3: any, form4: any, form5: any){
    var surveyDetails = this.db.database.ref('RY').child('surveyDetails');
    surveyDetails.push({
      "dateOfSubmission": this.dateOfSubmission,
      "overAllTimeTaken": localStorage.getItem('overAllTimeForSurvey'),
      "phase1EducationalBackground" : {
        "name": form1.name,
        "mailId": form1.mailId,
        "dateOfBirth": form1.dob,
        "mobileNumber": form1.mobileNumber,
        "pinCode": form1.pinCode,
        "latestEducation": form1.education,
        "favoriteSubject": form1.favoriteSubject,
        "programSkill": form1.programSkill,
        "consentApproved": localStorage.getItem('consent')
      },
      "phase2PersonalityAssessment": {
        "hobbiesList": form2.hobbiesList,
        "internetConnection": form2.internetConnection,
        "phoneApp": form2.phoneApp,
        "socialMediaUses": form2.socialMediaUses,
        "fundingForStudies": form2.fundingForStudies,
        "specialActivity": form2.specialActivity,
        "firstGraduate": form2.firstGraduate,
        "govtEmployee": form2.govtEmployee
      },
      "phase3CarrerPlanningAndIncomeForFuture": {
        "afterClgWork": form3.afterClgWork,
        "govtExam": form3.govtExam,
        "jobAwareness": form3.jobAwareness,
        "workAndIntershipExp": form3.workAndIntershipExp,
        "necessarySkills": form3.necessarySkills,
        "internetProfProspects": form3.internetProfProspects,
        "iitMaterialUse": form3.iitMaterialUse,
        "cvKnowledge": form3.cvKnowledge,
        "linkedIn": form3.linkedIn,
        "specialTalent": form3.specialTalent
      },
      "phase4ProgrammingSkills": {
        "aboutProgramSkills": form4.phase4Ques1,
        "awareOfSkillsRequired": form4.phase4Ques2,
        "technologyJobs": form4.phase4Ques3,
      },
      "phase5FollowingQuestions": {
        "4G5GInternet": form5.phase5Ques1,
        "workingHours": form5.phase5Ques2,
        "encourageRY": form5.phase5Ques3,
        "suggestionsForPoorStudents": form5.phase5Ques4,
        "noOfSchools": form5.phase5Ques5,
        "noOfColleges": form5.phase5Ques6,
        "transportation": form5.phase5Ques7,
        "resume": form5.phase5Ques8
      }
    })
    localStorage.removeItem('consent');
    localStorage.removeItem('surveyPhase1Form');
    localStorage.removeItem('surveyPhase2Form');
    localStorage.removeItem('surveyPhase3Form');
    localStorage.removeItem('surveyPhase4Form');
    localStorage.removeItem('surveyPhase5Form');
    localStorage.removeItem('formTimerStartTime');
    localStorage.removeItem('surveyLanguage');
  }

  // Progress bar
  getProgress(): number {
    return this.progressValue;
  }

  setProgress(value: number): void {
    this.progressValue = value;
  }

  timerSendEvent(){
    this.subjectOfTimer.next('value')
  }

  timerGetEvent(): Observable<any>{
    return this.subjectOfTimer.asObservable();
  }
}
