<app-header></app-header>
<div *ngIf="enableProgress" class="progress-bar progressBar" [style.width.%]="progress"></div>
<div *ngIf="enableProgress" class="timer">{{ elapsedTime }}</div>
<!-- News letter success message -->
<div *ngIf="this.newsLetter == true" class="newsLetterSuccessMsgBox">
    <img src="assets\images\greenTick.svg"  style="padding-right: 10px;" class="greenTickImg" alt="">
    News Letter requested successfully!
</div>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-top-arrow></app-top-arrow>
<app-footer></app-footer>
