import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { contactForm } from '../models/contact.model';
import { RyMailServiceService } from '../service/ry-mail-service.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  Button:boolean=true;

  spinner: boolean = true;
  contact_form_details! : FormGroup; 

    contact_form_group : FormGroup = this.fb.group({   
      contactFormName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[a-zA-Z][\sa-zA-Z ]*')]],
      contactFormEmail: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      contactFormCmts: ['', [Validators.required, Validators.minLength(2), Validators.pattern("^[a-zA-Z0-9.,-/@()&''?!_=+][\sa-zA-Z0-9 .,-/@()&''?!_=+]*")]], 
    })

  constructor( private fb: FormBuilder, private db: AngularFireDatabase, private mailService: RyMailServiceService) { }

  ngOnInit(): void {
    this.spinner = false;
  }

   // Pushing the contact-form to the firebase data base
   onSubmit()  {
    window.scroll({top:0, behavior: 'smooth'});
    this.mailsendingForUserComments();
    this.submitContactUS(this.contact_form_group.value);
    document.getElementById("contactMsgSuccessMsgBoxId")!.style.display = "block";
    setTimeout(() => {
      document.getElementById("contactMsgSuccessMsgBoxId")!.style.display = "none";
    }, 3000);
    this.contact_form_group.reset();
  }

  mailsendingForUserComments() {
    var object = {
      'userName' : this.contactFormName!.value,
      'email' : this.contactFormEmail!.value,
      'userComments' : this.contactFormCmts!.value
    }

    console.log("user value",object);
 
    this.mailService.userCommentsMailFunction(object).subscribe({
      next: (v) => console.log(v),
      error: (e) => console.error(e),
      complete: () => console.info('complete') 
    })
  }

  shouldNotBeNumber(nameReg: RegExp){
    return (control: { value: any; }) =>{
      let isNotNumberic = true;
      let name = control.value;
      for(let i =0; i<name.length; i++){
        if(parseInt(name[i]) < 65 || parseInt(name[i]) > 122){
          isNotNumberic = false;
          break;
        }
      }
      return isNotNumberic ? null: { shouldNotBeNumber: { value : control.value} };
    }
  }

  submitContactUS(formValue:contactForm){
    var contactUs = this.db.database.ref('messages');
    contactUs.push({ 
      name:formValue.contactFormName, 
      email: formValue.contactFormEmail, 
      message: formValue.contactFormCmts});
  }

  get contactFormName() {
    return this.contact_form_group.get('contactFormName');
  }

  get contactFormEmail() {
    return this.contact_form_group.get('contactFormEmail');
  }

  get contactFormCmts() {
    return this.contact_form_group.get('contactFormCmts');
  }

}
