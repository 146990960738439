import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.css']
})
export class ImageGalleryComponent implements OnInit {

  imageDataFromDatabase: any;

  spinner: boolean = true;

  constructor(private db: AngularFireDatabase) {
    this.db.list('RY/photoGalleryImages').valueChanges().subscribe(data => {
      var array = []
      for (let spot of data) {
        array.push(spot)
      }
      this.imageDataFromDatabase = array
      this.spinner = false
    })
  }

  ngOnInit(): void {}
}
