import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SurveyFormService } from '../survey-form.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SUPPORTED_LANGUAGES } from '../model/supported-languages';

@Component({
  selector: 'app-survey-phase1',
  templateUrl: './survey-phase1.component.html',
  styleUrls: ['./survey-phase1.component.css']
})
export class SurveyPhase1Component implements OnInit {

  checkOthersOpt: boolean = false;

  // Form Group
  aboutSurveyPhase1Form!: FormGroup;

  aboutSurveyFormUpload(){
    this.aboutSurveyPhase1Form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern('^[a-zA-Z][\sa-zA-Z ]*')]],
      mailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      dob: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      pinCode: ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
      education: ['', [Validators.required]],
      favoriteSubject: ['', [Validators.required]],
      programSkill: ['', [Validators.required]]
    })
  }

  patchValueFromLocal() {
    var phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!); 
    if(phase1Form != null) {
      var slicedNumber = `${phase1Form.mobileNumber}`.slice(3)
      var integerPhoneNumber = Number(slicedNumber)
  
      this.aboutSurveyPhase1Form.patchValue({
        name : phase1Form.name,
        mailId : phase1Form.mailId,
        dob : phase1Form.dob,
        mobileNumber : integerPhoneNumber,
        pinCode : phase1Form.pinCode,
        education : phase1Form.education,
        favoriteSubject : phase1Form.favoriteSubject,
        programSkill : phase1Form.programSkill,
        consentApprove : phase1Form.consentApprove
      })
    }   
  }

  surveyRedirectToPendingPage() {
    // Form 1
    var phase1Form = [];
    phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    // Form 2
    var phase2Form = [];
    phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    // Form 3
    var phase3Form = [];
    phase3Form = JSON.parse(localStorage.getItem('surveyPhase3Form')!);
    // Form 4
    var phase4Form = [];
    phase4Form = JSON.parse(localStorage.getItem('surveyPhase4Form')!);
    // Form 5
    var phase5Form = [];
    phase5Form = JSON.parse(localStorage.getItem('surveyPhase5Form')!);

    if (phase1Form != null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['/phase2']).then(() => {
        alert("phase 1 is completed, lets start from phase 2");
      })
    } else if (phase1Form != null && phase2Form != null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['/phase3']).then(() => {
        alert("phase 1 and 2 is completed, lets start from phase 3");
      })
    } else if (phase1Form != null && phase2Form != null && phase3Form != null && phase4Form == null) {
      this.router.navigate(['/phase4']).then(() => {
        alert("phase 1, 2 and 3 is completed, lets start from phase 4")
      })
    } else if (phase1Form != null && phase2Form != null && phase4Form != null && phase4Form != null) {
      this.router.navigate(['/phase5']).then(() => {
        alert("phase 1, 2, 3 and 4 is completed, lets start from phase 5")
      })
    }
  }

  languages = SUPPORTED_LANGUAGES;

  spinner: boolean = true;

  @ViewChild('surveyVideo') mySurveyVideo!: ElementRef;

  @ViewChild('otherInput') otherInput!: ElementRef;

  constructor(private formBuilder: FormBuilder, private uploadService: SurveyFormService, public translate: TranslateService, private router: Router) {
    var language = (localStorage.getItem('surveyLanguage')!)
    translate.addLangs(['en', 'ta']);
    if(language) {
      translate.setDefaultLang(language);
    } else {
      translate.setDefaultLang('en')
    }
  }

  ngOnInit(): void {
    this.surveyRedirectToPendingPage();
    this.aboutSurveyFormUpload();
    this.localstorageLanguageSet();
    this.spinner = false
  }

  switchLang(lang: string) {
    if(this.aboutSurveyPhase1Form.value['name'] == '' && this.aboutSurveyPhase1Form.value['mailId'] == '' && this.aboutSurveyPhase1Form.value['dob'] == '' && this.aboutSurveyPhase1Form.value['mobileNumber'] == '' &&
    this.aboutSurveyPhase1Form.value['pinCode'] == '' && this.aboutSurveyPhase1Form.value['education'] == '' && this.aboutSurveyPhase1Form.value['favoriteSubject'] == '' && this.aboutSurveyPhase1Form.value['programSkill'] == '') {
      localStorage.removeItem('surveyLanguage');
      localStorage.setItem('surveyLanguage', JSON.stringify(lang));
      this.localstorageLanguageSet();
    } else {
      alert("Entered data will be lost");
      this.aboutSurveyPhase1Form.get('name')!.setValue('')
      this.aboutSurveyPhase1Form.get('mailId')!.setValue('')
      this.aboutSurveyPhase1Form.get('dob')!.setValue('')
      this.aboutSurveyPhase1Form.get('mobileNumber')!.setValue('')
      this.aboutSurveyPhase1Form.get('pinCode')!.setValue('')
      this.aboutSurveyPhase1Form.get('education')!.setValue('')
      this.aboutSurveyPhase1Form.get('favoriteSubject')!.setValue('')
      this.aboutSurveyPhase1Form.get('programSkill')!.setValue('');
      localStorage.removeItem('surveyLanguage');
      localStorage.setItem('surveyLanguage', JSON.stringify(lang));
      this.localstorageLanguageSet();
    }
    
  }

  localstorageLanguageSet() {
    var language = JSON.parse(localStorage.getItem('surveyLanguage')!);
    this.translate.use(language);
  }

  checkedOthers(){
    this.checkOthersOpt = true;
    this.aboutSurveyPhase1Form.get('favoriteSubject')?.setValidators([Validators.required, Validators.pattern('^[a-zA-Z][\sa-zA-Z ]*')]);
    this.aboutSurveyPhase1Form.get('favoriteSubject')?.updateValueAndValidity();

  }

  changeOtherValue(){
    this.checkOthersOpt = false;
    this.otherInput.nativeElement.value = '';
    this.aboutSurveyPhase1Form.get('favoriteSubject')?.setValidators([Validators.required]);
    this.aboutSurveyPhase1Form.get('favoriteSubject')?.updateValueAndValidity();
  }

  // Submit phase 1 form --------------------------
  submitPhase1(){
    this.aboutSurveyPhase1Form.value['mobileNumber'] = "+91"+this.aboutSurveyPhase1Form.value['mobileNumber']
    this.uploadService.getPhase1Form(this.aboutSurveyPhase1Form.value);
  }

  // Validating functions --------------------------
  get name(){
    return this.aboutSurveyPhase1Form.get('name')
  }

  shouldNotBeNumber(nameReg: RegExp){
    return (control: { value: any; }) =>{
      let isNotNumberic = true;
      let name = control.value;
      for(let i =0; i<name.length; i++){
        if(parseInt(name[i]) < 65 || parseInt(name[i]) > 122){
          isNotNumberic = false;
          break;
        }
      }
      return isNotNumberic ? null: { shouldNotBeNumber: { value : control.value} };
    }
  }

  get mailId(){
    return this.aboutSurveyPhase1Form.get('mailId')
  }

  get dob(){
    return this.aboutSurveyPhase1Form.get('dob')
  }

  get mobileNumber(){
    return this.aboutSurveyPhase1Form.get('mobileNumber')
  }

  get pinCode(){
    return this.aboutSurveyPhase1Form.get('pinCode')
  }

  get education(){
    return this.aboutSurveyPhase1Form.get('education')
  }

  get favoriteSubject(){
    return this.aboutSurveyPhase1Form.get('favoriteSubject')
  }

  get programSkill(){
    return this.aboutSurveyPhase1Form.get('programSkill')
  }

  get consentApprove(){
    return this.aboutSurveyPhase1Form.get('consentApprove')
  }
}
