<div class="container">
    <div class="row text">
        <div class="col">
            <div>
                To be updated...
            </div>
            <div>
                We are currently working on something awesome. Stay tuned!
            </div>
        </div>
    </div>
</div>
