<div class="header">
    <div class="container header_main">
        <div class="row">
            <!-- RY logo -->
            <div class="col-1">
                <img class="RY_LOGO" routerLink="/Home" src="../../assets/svg/RY logo.svg" alt="RY_LOGO">
            </div>
            <!-- RY home -->
            <div class="col-8 HeaderNavigationTextButtons">
                <div>
                    <div class="header_home_txet">
                        <p class="p_tag_defult_style_removing header_poniter" id="homeLinkInHeaderNavebar"
                            routerLink="/Home" routerLinkActive="activeNavlink">
                            Home</p>
                    </div>
                </div>
                <!-- RY about -->
                <div>
                    <div class="header_About_txet">
                        <p class="p_tag_defult_style_removing header_poniter" id="aboutLinkInHeaderNavebar"
                            routerLink="/About" routerLinkActive="activeNavlink">
                            About</p>
                    </div>
                </div>
                <!-- RY Gallery -->
                <div>
                    <div class="header_Gallery_txet">
                        <p class="p_tag_defult_style_removing header_poniter imageGalleryHeaderText"
                            id="galleryLinkInHeaderNavebar" routerLink="/imageGallery" routerLinkActive="activeNavlink">
                            Gallery</p>
                    </div>
                </div>
                <!-- RY Success Stories -->
                <div>
                    <div class="header_successStory_txet">
                        <p class="p_tag_defult_style_removing header_poniter successStoriesHeaderText"
                            id="galleryLinkInHeaderNavebar" routerLink="/successStories"
                            routerLinkActive="activeNavlink">Stories</p>
                    </div>
                </div>
                <!-- Volunteering -->
                <div>
                    <div class="header_volunteer_txet">
                        <p class="p_tag_defult_style_removing header_poniter" id="volunteerLinkInHeaderNavebar"
                            routerLink="/becomeVolunteerPage" routerLinkActive="activeNavlink">Volunteering</p>
                    </div>
                </div>
                <!-- RY ContactUs -->
                <div>
                    <div class="header_Contact_txet">
                        <p class="p_tag_defult_style_removing header_poniter" id="contactUsLinkInHeaderNavebar"
                            routerLink="/ContactUs" routerLinkActive="activeNavlink">Contact</p>
                    </div>
                </div>
            </div>
            <!-- Take survey btn -->
            <div class="col-3 takeThisSurveyButtonContainer">
                <div class="header_btn" *ngIf="ShowSurveyButton">
                    <button class="take_survey_btn btn" (click)="navigateToSurveyPage()">Take this survey</button>
                </div>
                <img class="NavBarImg" src="../../assets/svg/navBar.svg" alt="navBar" data-bs-target="#NavBarModalID" data-bs-toggle="modal">
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="NavBarModalID" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog HeadermodelContainer" role="document">
        <div class="modal-content">
            <div class="modal-header modalHEADER">
                <button class="HeaderModalCloseBTN" type="button" data-bs-dismiss="modal" aria-label="Close"
                    aria-hidden="true">
                    <img src="../../assets/svg/modalCloseBtn.svg">
                </button>
            </div>
            <div class="MODELTEXT" (click)="navigateToSurveyPage()" data-bs-dismiss="modal" aria-label="Close"
                aria-hidden="true">
                <p class="p_tag_defult_style_removing">Take this survey</p>
            </div>
            <hr>
            <div class="MODELTEXT" routerLink="/Home" routerLinkActive="active" data-bs-dismiss="modal"
                aria-label="Close" aria-hidden="true">
                <p class="p_tag_defult_style_removing">Home</p>
            </div>
            <hr>
            <div class="MODELTEXT" routerLink="/About" routerLinkActive="active" data-bs-dismiss="modal"
                aria-label="Close" aria-hidden="true">
                <p class="p_tag_defult_style_removing">About</p>
            </div>
            <hr>
            <div class="MODELTEXT" routerLink="/imageGallery" routerLinkActive="active"
                data-bs-dismiss="modal" aria-label="Close" aria-hidden="true">
                <p class="p_tag_defult_style_removing">Gallery</p>
            </div>
            <hr>
            <div class="MODELTEXT" routerLink="/successStories" routerLinkActive="active" data-bs-dismiss="modal"
                aria-label="Close" aria-hidden="true">
                <p class="p_tag_defult_style_removing">Stories</p>
            </div>
            <hr>
            <div class="MODELTEXT" routerLink="/ContactUs" routerLinkActive="active" data-bs-dismiss="modal"
                aria-label="Close" aria-hidden="true">
                <p class="p_tag_defult_style_removing">Contact</p>
            </div>
            <hr>
            <div class="MODELTEXT CareerModalText" routerLink="/becomeVolunteerPage" routerLinkActive="active" data-bs-dismiss="modal"
                aria-label="Close" aria-hidden="true">
                <p class="p_tag_defult_style_removing">Volunteering</p>
            </div>
        </div>
    </div>
</div>