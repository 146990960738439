<div #animation class="container takeSurveyMainBox animate__animated animate__fadeInDown">
    <!-- About the survey and Video box ----------------------------------------->
    <div class="surveyContentMainBox">
        <!-- Welcome to the rural youth survey page -->
        <div class="row">
            <div class="col">
                <div class="welcomeHeadingInTakeS">Welcome to the Rural Youth Survey!</div>
                <div class="welcomeContTextInTakeS">
                    We are excited to invite you to participate in an important survey focusing on the lives,
                    aspirations, and challenges of rural youth aged between 13 and 33 years. Your voice is crucial, and
                    we want to hear from you!
                </div>
            </div>
        </div>
        <div class="irsHeadingBox">
            <div class="irsHeading">Redefining the Future: A Comprehensive Look at Rural Youth in India</div>
            <!-- <div class="irsSubContent">(Initiative of IIT Madras alumni to help rural youth for their skill upgrade and employment)</div> -->
        </div>
        <!-- About the survey content ------------------------------------------->
        <div class="row">
            <div class="col-xl-6 aboutSurveyContentHead">
                Rural youth in India often face challenges like unemployment and poverty. We're committed to making a
                difference through targeted nationwide solutions:
                <div class="pointsContentBox">
                    <ul class="pointsList">
                        <li>Unlock Potential: <span>Hightligth untapped opportunities.</span></li>
                        <li>Boost Employability: <span>Improve skills for better job porspects.</span></li>
                        <li>Grow throught Training: <span>Enable passions with specialized guidance.</span></li>
                    </ul>
                </div>
                Your participation in this survey helps us achieve these goals. Your support is crucial, and we greatly
                appreciate it.
            </div>
            <div class="col-xl-6 aboutSurveyAndVideoBox">
                <!-- <div class="aboutSurveyHeading">About the survey</div> -->
                <div class="surveyVideo">
                    <video
                        src="https://firebasestorage.googleapis.com/v0/b/rural-youth-2f166.appspot.com/o/videos%2FRY1%201.mp4?alt=media&token=6d2aa964-9c88-4386-b108-43ac4c268d43\"
                        width="827px" height="438.06px" class="surveyPlayVideo" autoplay [muted]="'muted'"
                        disablepictureinpicture loop controls controlsList="nodownload nofullscreen noplaybackrate"
                        (click)="playPauseVideo()" #surveyVideo></video>
                </div>
            </div>
        </div>
        <!-- Video box below 1400px -------------------------------------------->
        <div class="videoBoxForBelowRes">
            <video
                src="https://firebasestorage.googleapis.com/v0/b/rural-youth-2f166.appspot.com/o/videos%2FRY1%201.mp4?alt=media&token=6d2aa964-9c88-4386-b108-43ac4c268d43\"
                width="827px" height="438.06px" class="videoBelowRes" autoplay [muted]="'muted'" disablepictureinpicture
                loop controls controlsList="nodownload nofullscreen noplaybackrate" (click)="playPauseVideo()"
                #surveyVideo></video>
        </div>
        <!-- Purpose of the survey -->
        <div class="row">
            <div class="col">
                <div class="purposeOfSurveyHeading">Purpose of the Survey:</div>
                <div class="purposeOfSurveyContText">
                    This survey seeks to understand the unique experiences, opinions, and needs of young people living
                    in rural areas. The insights gathered will aid in shaping policies, programs, and initiatives
                    designed to support and empower rural youth in various aspects of life, including education,
                    employment, healthcare, technology, and community engagement.
                </div>
            </div>
        </div>
        <!-- Who can participate -->
        <div class="row">
            <div class="col">
                <div class="whoCanParticipateHead">Who Can Participate:</div>
                <ul class="whoCanParticipateCont">
                    <li>Individuals aged between 13 and 33 years.</li>
                    <li>Residents of rural or semi-rural areas.</li>
                </ul>
            </div>
        </div>
        <!-- What to Expect -->
        <div class="row">
            <div class="col">
                <div class="whatToExpectHead">What to Expect:</div>
                <ul class="whatToExpectCont">
                    <li>The survey will take approximately 10-15 minutes to complete.</li>
                    <li>Questions will cover various topics related to your daily life, education, career goals, access to services, and more.</li>
                    <li>Your responses will be completely confidential and anonymous. No personal identifying information will be shared or published.</li>
                </ul>
            </div>
        </div>
        <!-- Why participate -->
        <div class="row">
            <div class="col">
                <div class="whyParticipateHead">Why Participate:</div>
                <ul class="whyParticipateCont">
                    <li>Share your unique perspective and experiences.</li>
                    <li>Contribute to a larger understanding of rural youth's needs and challenges.</li>
                    <li>Help influence decisions and programs that can positively impact your community and peers.</li>
                </ul>
            </div>
        </div>
        <!-- How to complete the survey -->
        <div class="row">
            <div class="col">
                <div class="howToCompleteHead">How to Complete the Survey:</div>
                <ol class="howToCompleteCont">
                    <li>Read each question carefully and provide honest and thoughtful responses.</li>
                    <li>Feel free to skip any question you are not comfortable answering.</li>
                    <li>Click the "Submit" button at the end of the survey to ensure your responses are recorded.</li>
                </ol>
            </div>
        </div>
        <!-- Rules ------------------------------------------------------------->
        <div class="rulesOfSurveyBox">
            <div class="rulesHeading">Rules: </div>
            <ol class="rulesContentBox">
                <li>One Attempt & CV Requirement: <span>Only one submission per Email address, with an updated CV
                        ready.</span></li>
                <li>Honesty & Compliance: <span>No fake responses, answer all questions truthfully, and agree to terms
                        and conditions.</span></li>
                <li>Completion & Contact Details: <span>Complete the survey in one sitting and provide full name and
                        valid email.</span></li>
                <li>Confidentiality & Consent: <span>Personal information will be kept confidential, and participation
                        allows response usage for research.</span></li>
            </ol>
        </div>
        <!-- Note alert  -->
        <div class="notesOfSurveyBox">
            <div class="notesHeading">Note:</div>
            <div class="surveyWarningText">
                Please be aware that once you submit a page of the survey, you will not be able to make any changes or
                edit
                the responses on that page.
                We strongly advise you to review each question thoroughly and provide accurate and complete answers
                before
                submitting.
            </div>
        </div>
        <!-- Consent box --------------------------------------------->
        <div class="surveyConsentBox">
            <div class="consentHeading">Consent</div>
            <div class="acceptConsentBox">
                <label class="consentContainer">
                    <input type="checkbox" (change)="onChangeConsent()">
                    <span class="consentCheckMark"></span>
                </label>
                <div class="acceptConsentContent">
                    I agree to do the survey with my own consent and submit correct information to the best of my
                    knowledge in order for it to be relevant for my career.
                </div>
            </div>
            <!-- Validating errors ------------------------->
            <!-- <div *ngIf="consentApprove?.invalid && (consentApprove?.dirty || consentApprove?.touched)"
                class="errorsBox">
                <div *ngIf="consentApprove?.errors?.['required']" class="consetnErrorMsg">
                    Please agree consent
                </div>
            </div> -->
        </div>
        <!-- start suryve btn -->
        <button class="surveyNextBtn" routerLink="/phase1" routerLinkActive="active" (click)="setConsent()"
            [routerLinkActiveOptions]="{exact:true}" [disabled]="enableStartBtn == false">Start Survey
        </button>
    </div>
</div>