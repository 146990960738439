import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CareerComponent } from './career/career.component';
import { TakesurveyComponent } from './takesurvey/takesurvey.component';
import { SurveyPhase2Component } from './survey-phase2/survey-phase2.component';
import { SurveyPhase3Component } from './survey-phase3/survey-phase3.component';
import { SurveyPhase4Component } from './survey-phase4/survey-phase4.component';
import { SurveyPhase5Component } from './survey-phase5/survey-phase5.component';
import { WhyRuralYouthComponent } from './why-rural-youth/why-rural-youth.component';
import { BecomeVolunteerComponent } from './become-volunteer/become-volunteer.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { BriefSuccessStoryComponent } from './brief-success-story/brief-success-story.component';
import { SurveyPhase1Component } from './survey-phase1/survey-phase1.component';

const routes: Routes = [
  {path: 'Home', component : HomeComponent},
  {path: 'About', component : AboutComponent},
  {path: 'ContactUs', component : ContactusComponent},
  {path: 'Career', component : CareerComponent},
  {path: 'TakeSurvey', component : TakesurveyComponent},
  {path: 'phase1', component: SurveyPhase1Component},
  {path: 'phase2', component: SurveyPhase2Component},
  {path: 'phase3', component: SurveyPhase3Component},
  {path: 'phase4', component: SurveyPhase4Component},
  {path: 'phase5', component: SurveyPhase5Component},
  {path: 'whyRuralYouth', component: WhyRuralYouthComponent},
  {path: 'becomeVolunteerPage', component: BecomeVolunteerComponent},
  {path: 'imageGallery', component: ImageGalleryComponent},
  {path: 'successStories', component: SuccessStoriesComponent},
  {path: 'briefArticles', component: BriefSuccessStoryComponent},
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'Home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
