<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container surveyPhase1MainBox animate__animated animate__fadeInDown">
    <!-- Welcome to our survey -->
    <div class="welcomeTextBox">
        <div class="welcomeTextHead">Welcome to Our Survey!</div>
        <div class="welcomeContText">
            Divided into five phases, this survey begins on this page. It's conducted in different languages to suit
            your preference. Please choose your language to begin.
        </div>
    </div>
    <!-- About the survey phase 2 ------------------------------->
    <div class="phase1MainBox">
        <div class="phase1Heading">{{'phase1.title' | translate}}</div>
        <!-- <div class="phase1Content">{{'phase1.noOfPhase' | translate}}</div> -->
        <!-- Choose survey language --------------------------------------->
        <div class="chooseSurveyLangBox">
            <div class="chooseLangHeading">Choose survey language:</div>
            <div class="selectLang">
                <select class="chooseLang" #selectedLang (change)="switchLang(selectedLang.value)">
                    <option *ngFor="let language of languages" [value]="language.code"
                        [selected]="language.code === translate.currentLang">{{language.name}}</option>
                </select>
            </div>
        </div>
        <form [formGroup]="aboutSurveyPhase1Form">
            <div class="surveyNameBox">
                <div *ngFor="let question1 of 'phase1.qust' | translate">
                    <div class="nameHeading">{{question1.qustTitle}}</div>
                    <input *ngIf="question1.inputType == 'input'" type="text" class="nameInput"
                        formControlName="{{question1.formControlName}}" placeholder="Your Answer">
                    <div *ngIf="question1.inputType == 'input'" class="nameInputLine"></div>
                    <!-- mobile number --------------------------->
                    <span class="numberSpan" *ngIf="question1.inputType == 'mobileNumber'">+91 <input type="text"
                            class="nameInput" formControlName="{{question1.formControlName}}"
                            placeholder="Your Answer"></span>
                    <div *ngIf="question1.inputType == 'mobileNumber'" class="nameInputLine"></div>
                    <!-- Date of birth --------------------------->
                    <div *ngIf="question1.inputType == 'date'" class="subDateHead">Date</div>
                    <input *ngIf="question1.inputType == 'date'" type="date" max="2030-12-31" class="dobInput"
                        formControlName="dob" placeholder="Your Answer">
                    <div *ngIf="question1.inputType == 'date'" class="dobInputLine"></div>
                    <!-- Select option ------------------------->
                    <select *ngIf="question1.inputType == 'select'" class="selectSkills" formControlName="programSkill">
                        <option value="" selected disabled hidden>Choose from here</option>
                        <option value="Java">Java</option>
                        <option value="C/C++">C/C++</option>
                        <option value="Python">Python</option>
                        <option value="Others">Others</option>
                        <option value="No program skill">No programming skills</option>
                    </select>
                    <!-- Radio button --------------------------->
                    <div class="educationBox" *ngFor="let opt of question1.options; let i= index">
                        <label *ngIf="question1.formControlName !=='favoriteSubject'"
                            class="educationContainer1">{{opt}}
                            <input type="radio" formControlName="{{question1.formControlName}}"
                                name="{{question1.formControlName}}" value="{{opt}}">
                            <!-- <input *ngIf="question1.formControlName =='favoriteSubject'" (click)="changeOtherValue()" type="radio" formControlName="favoriteSubject" name="favoriteSubject" value="{{opt}}"> -->
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <!-- Favorite subject -------------------------->
                    <div *ngIf="question1.formControlName =='favoriteSubject'">
                        <div class="favoriteBox">
                            <label for="mathematics" class="favoriteContainer1">{{'phase1.maths' | translate}}
                                <input type="radio" formControlName="favoriteSubject" name="favoriteSubject"
                                    id="mathematics" (click)="changeOtherValue()" value="Mathematics">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="favoriteBox">
                            <label for="computerScience" class="favoriteContainer1">{{'phase1.computer' |
                                translate}}
                                <input type="radio" formControlName="favoriteSubject" name="favoriteSubject"
                                    id="computerScience" (click)="changeOtherValue()" value="Computer science">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="favoriteBox">
                            <label class="favoriteContainer1">{{'phase1.others' | translate}}
                                <input type="radio" name="favoriteSubject" [checked]="this.checkOthersOpt == true">
                                <span class="checkmark"></span>
                            </label>
                            <input type="text" formControlName="favoriteSubject" class="otherSubjectInput"
                                (click)="checkedOthers()" #otherInput placeholder="Please mention">
                        </div>
                    </div>
                    <!-- Validating errors ------------------------->
                    <div *ngIf="question1.formControlName== 'name'">
                        <div *ngIf="name?.invalid && (name?.dirty || name?.touched)" class="errorsBox">
                            <div *ngIf="name?.errors?.['required']" class="errorMsg">
                                Name should not be empty
                            </div>
                            <!-- <div *ngIf="name?.errors?.['maxlength']" class="errorMsg">
                                Name should not be more than 30 character
                            </div> -->
                            <div *ngIf="name?.errors?.['minlength']" class="errorMsg">
                                Name should be minimum 3 character
                            </div>
                            <div *ngIf="name?.errors?.['pattern']" class="errorMsg">
                                Name should be alphabets
                            </div>
                        </div>
                    </div>
                    <!-- Mail error msg -->
                    <div *ngIf="question1.formControlName== 'mailId'">
                        <div *ngIf="mailId?.invalid && (mailId?.dirty || mailId?.touched)" class="errorsBox">
                            <div *ngIf="mailId?.errors?.['required']" class="errorMsg">
                                Email should not be empty
                            </div>
                            <div *ngIf="mailId?.errors?.['pattern']" class="errorMsg">
                                Email must be valid
                            </div>
                        </div>
                    </div>
                    <!-- Mobile number ---------------->
                    <div *ngIf="question1.formControlName== 'mobileNumber'">
                        <div *ngIf="mobileNumber?.invalid && (mobileNumber?.dirty || mobileNumber?.touched)"
                            class="errorsBox">
                            <div *ngIf="mobileNumber?.errors?.['required']" class="errorMsg">
                                Mobile number should not be empty
                            </div>
                            <!-- <div *ngIf="mobileNumber?.errors?.['maxlength']" class="errorMsg">
                                Mobile number should be max 10
                            </div> -->
                            <div *ngIf="mobileNumber?.errors?.['pattern']" class="errorMsg">
                                Mobile number should be valid
                            </div>
                        </div>
                    </div>
                    <!-- pinCode -------------------------->
                    <div *ngIf="question1.formControlName== 'pinCode'">
                        <div *ngIf="pinCode?.invalid && (pinCode?.dirty || pinCode?.touched)" class="errorsBox">
                            <div *ngIf="pinCode?.errors?.['required']" class="errorMsg">
                                Pin Code should not be empty
                            </div>
                            <div *ngIf="pinCode?.errors?.['pattern']" class="errorMsg">
                                Pin Code must be valid
                            </div>
                        </div>
                    </div>
                    <!-- Education ---------------------->
                    <div *ngIf="question1.formControlName== 'education'">
                        <div *ngIf="education?.invalid && (education?.dirty || education?.touched)" class="errorsBox">
                            <div *ngIf="education?.errors?.['required']" class="errorMsg">
                                Choose any one of the education
                            </div>
                        </div>
                    </div>
                    <!-- favoriteSubject ---------------------->
                    <div *ngIf="question1.formControlName== 'favoriteSubject'">
                        <div *ngIf="favoriteSubject?.invalid && (favoriteSubject?.dirty || favoriteSubject?.touched)" class="errorsBox">
                            <div *ngIf="favoriteSubject?.errors?.['required']" class="errorMsg">
                                Choose any one of the favorite subject
                            </div>
                            <div *ngIf="favoriteSubject?.errors?.['pattern']" class="errorMsg">
                                Favorite subject should be alphabets
                            </div>
                        </div>
                    </div>
                    <!-- Education ---------------------->
                    <div *ngIf="question1.formControlName== 'programSkill'">
                        <div *ngIf="programSkill?.invalid && (programSkill?.dirty || programSkill?.touched)" class="errorsBox">
                            <div *ngIf="programSkill?.errors?.['required']" class="errorMsg">
                                Choose any one of the programming skill
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Next button box ----------------------------------------->
                <div class="surveyNextBtnBox">
                    <button class="surveyNextBtn" routerLink="/phase2" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact:true}" [disabled]="!this.aboutSurveyPhase1Form.valid"
                        (click)="submitPhase1()">Next
                    </button>
                </div>
            </div>
        </form>
        <!-- Name Box --------------------------------------------------->

    </div>
</div>