import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CareerComponent } from './career/career.component';
import { TakesurveyComponent } from './takesurvey/takesurvey.component';
import { FooterComponent } from './footer/footer.component';
import { SurveyPhase2Component } from './survey-phase2/survey-phase2.component';
import { SurveyPhase4Component } from './survey-phase4/survey-phase4.component';
import { SurveyPhase5Component } from './survey-phase5/survey-phase5.component';
import { SurveyPhase3Component } from './survey-phase3/survey-phase3.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { WhyRuralYouthComponent } from './why-rural-youth/why-rural-youth.component';
import { BecomeVolunteerComponent } from './become-volunteer/become-volunteer.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { WhySurveyComponent } from './why-survey/why-survey.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { BriefSuccessStoryComponent } from './brief-success-story/brief-success-story.component';
import { SurveyPhase1Component } from './survey-phase1/survey-phase1.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TopArrowComponent } from './top-arrow/top-arrow.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    ContactusComponent,
    CareerComponent,
    TakesurveyComponent,
    FooterComponent,
    SurveyPhase2Component,
    SurveyPhase4Component,
    SurveyPhase5Component,
    SurveyPhase3Component,
    WhyRuralYouthComponent,
    BecomeVolunteerComponent,
    ImageGalleryComponent,
    WhySurveyComponent,
    SuccessStoriesComponent,
    BriefSuccessStoryComponent,
    SurveyPhase1Component,
    SpinnerComponent,
    TopArrowComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}