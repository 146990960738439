import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cvUpload } from '../model/cvUpload';
import { Router } from '@angular/router';
import { SurveyFormService } from '../survey-form.service';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from '../model/supported-languages';
import { RyMailServiceService } from '../service/ry-mail-service.service';

@Component({
  selector: 'app-survey-phase5',
  templateUrl: './survey-phase5.component.html',
  styleUrls: ['./survey-phase5.component.css']
})
export class SurveyPhase5Component implements OnInit {

  phase5_survey_form!: FormGroup;
  selectCVLabel: boolean = true;
  CVFile?: FileList;
  CVCurrentFile?: cvUpload;
  percentage: any;
  cvFileLocalData: any;
  languages = SUPPORTED_LANGUAGES;

  overAllTime: any;

  phase5FormGroupData() {
    this.phase5_survey_form = this.formBuilder.group({
      phase5Ques1: ['', [Validators.required]],
      phase5Ques2: ['', [Validators.required]],
      phase5Ques3: ['', [Validators.required, Validators.pattern('^[a-zA-Z][\sa-zA-Z ]*')]],
      phase5Ques4: ['', [Validators.required, Validators.pattern('^[a-zA-Z][\sa-zA-Z ]*')]],
      phase5Ques5: ['', [Validators.required]],
      phase5Ques6: ['', [Validators.required]],
      phase5Ques7: ['', [Validators.required]],
      phase5Ques8: ['']
    })
  }

  spinner: boolean = true;

  constructor(private formBuilder: FormBuilder, private uploadService: SurveyFormService, private router: Router, public translate: TranslateService, private mailService: RyMailServiceService) {
    var language = JSON.parse(localStorage.getItem('surveyLanguage')!)
    translate.addLangs(['en', 'ta']);
    if(language) {
      translate.setDefaultLang(language);
    } else {
      translate.setDefaultLang('en')
    }
  }

  ngOnInit(): void {
    this.startSurveyAsNew();
    this.phase5FormGroupData();
    window.scrollTo(0, 0)
    // this.patchValueFromLocal();
    this.spinner = false;
  }

  patchValueFromLocal() {
    var phase5Form = JSON.parse(localStorage.getItem('surveyPhase5Form')!);
    var cvData = { 'fileName': phase5Form.phase5Ques8.fileName, 'fileUrl': phase5Form.phase5Ques8.fileUrl }

    if (phase5Form != null) {
      this.phase5_survey_form.patchValue({
        phase5Ques1: phase5Form.phase5Ques1,
        phase5Ques2: phase5Form.phase5Ques2,
        phase5Ques3: phase5Form.phase5Ques3,
        phase5Ques4: phase5Form.phase5Ques4,
        phase5Ques5: phase5Form.phase5Ques5,
        phase5Ques6: phase5Form.phase5Ques6,
        phase5Ques7: phase5Form.phase5Ques7
      });
      if (cvData.fileName != null) {
        this.phase5_survey_form.patchValue({
          phase5Ques8: cvData
        })
        const dataTransfer = new ClipboardEvent('').clipboardData || new DataTransfer();
        dataTransfer.items.add(new File(['my File'], phase5Form.phase5Ques8.fileName));
        const inputElement: HTMLInputElement = document.getElementById('selectCVInput') as HTMLInputElement;
        inputElement.files = dataTransfer.files;
      }
    }
  }

  startSurveyAsNew() {
    // Form 1
    var phase1Form = [];
    phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    // Form 2
    var phase2Form = [];
    phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    // Form 3
    var phase3Form = [];
    phase3Form = JSON.parse(localStorage.getItem('surveyPhase3Form')!);
    // Form 4
    var phase4Form = [];
    phase4Form = JSON.parse(localStorage.getItem('surveyPhase4Form')!);
    // Form 5
    var phase5Form = [];
    phase5Form = JSON.parse(localStorage.getItem('surveyPhase5Form')!);

    if (phase1Form == null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['TakeSurvey']) 
    } else if (phase1Form != null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['phase2']) 
    } else if (phase1Form != null && phase2Form != null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['phase3']) 
    } else if (phase1Form != null && phase2Form != null && phase3Form != null && phase4Form == null) {
      this.router.navigate(['phase4']) 
    } else if (phase1Form != null && phase2Form != null && phase4Form != null && phase4Form != null) {
      this.router.navigate(['phase5']) 
    }
  }

  // submit phase 5 form
  submitPhase5Form() {
    var cvFileFromService = this.uploadService.getCVLocalData();
    if(cvFileFromService) {
      this.getUploadedCVFile();
    } else {
      this.emptyCvUpload();
    }
    this.uploadService.timerSendEvent();
    this.overAllTime = localStorage.getItem('overAllTimeForSurvey')
    this.phase5_survey_form.value["phase5Ques8"] = this.cvFileLocalData;
    var localstorageUserDetails: any = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    var data = {
      'userName': localstorageUserDetails.name,
      'userEmailId': localstorageUserDetails.mailId
    }
    this.mailService.surveyCompleteMailFunction(data).subscribe(
      res => {
        console.log(res);
      }, err => {
        console.log(err);
      }
    )
    this.uploadService.getPhase5Form(this.phase5_survey_form.value);
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  clearForm() {
    this.router.navigate(['/Home'])
    localStorage.removeItem('overAllTimeForSurvey')
  }

  checkFormIsValid() {
    if((this.CVCurrentFile != undefined) && (this.CVCurrentFile != undefined) && (this.phase5_survey_form.valid)) {
      var cvFileFromService = this.uploadService.getCVLocalData();
      if(cvFileFromService != undefined && cvFileFromService != null && cvFileFromService != '') {
        return false;
      } else {
        return true;
      }
    } else if((this.CVCurrentFile == undefined || this.CVCurrentFile == null) && (this.phase5_survey_form.valid)) {
      return false;
    } else {
      return true;
    }
  }

  // CV file select
  selectCVFile(event: any) {
    document.getElementById('selectCVInput')!.style.color = "#5B5B5B";
    this.selectCVLabel = false;
    this.CVFile = event.target.files;
    this.uploadCVFile()
  }

  // send file data to service file
  uploadCVFile(): void {
    if (this.CVFile) {
      const file: File | null = this.CVFile.item(0);

      if (file) {
        this.CVCurrentFile = new cvUpload(file);
        this.uploadService.pushCVFileToStorage(this.CVCurrentFile).subscribe(
          percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
          },
          error => {
            console.log(error)
          }
        )
      }
    }
  }

  getUploadedCVFile() {
    var cvFileFromService = this.uploadService.getCVLocalData();
    this.cvFileLocalData = [];
    this.cvFileLocalData = {
      "fileName": cvFileFromService.cvFileName,
      "fileUrl": cvFileFromService.cvFileUrl
    }
  }

  emptyCvUpload() {
    this.cvFileLocalData = [];
    this.cvFileLocalData = {
      "fileName": 'user is not provided',
      "fileUrl": 'user is not provided'
    }
  }

  get phase5Ques1() {
    return this.phase5_survey_form.get('phase5Ques1')
  }

  get phase5Ques2() {
    return this.phase5_survey_form.get('phase5Ques2')
  }

  get phase5Ques3() {
    return this.phase5_survey_form.get('phase5Ques3')
  }

  get phase5Ques4() {
    return this.phase5_survey_form.get('phase5Ques4')
  }

  get phase5Ques5() {
    return this.phase5_survey_form.get('phase5Ques5')
  }

  get phase5Ques6() {
    return this.phase5_survey_form.get('phase5Ques6')
  }

  get phase5Ques7() {
    return this.phase5_survey_form.get('phase5Ques7')
  }

}
