import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-arrow',
  templateUrl: './top-arrow.component.html',
  styleUrls: ['./top-arrow.component.css']
})
export class TopArrowComponent implements OnInit {

  pageYoffset = 0;

  @HostListener('window:scroll',['$event']) onScroll(event:any){
    this.pageYoffset = window.pageYOffset
  }

  constructor() { }

  ngOnInit(): void {
  }

  scrollToTop(){
    window.scrollTo({top: 0, 'behavior' : 'smooth'})
  }

}
