<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container phase4_container_main animate__animated animate__fadeInDown">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col">
                    <div class="phase4_header_main">{{'phase4.title' | translate}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="phase4_header_body_content">{{'phase4.noOfPhase' | translate}}</div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col">
                    <div class="chooseSurveyLangBox">
                        <div class="chooseLangHeading">Choose survey language:</div>
                        <div class="selectLang">
                            <select class="chooseLang" #selectedLang (change)="switchLang(selectedLang.value)" style="pointer-events: none;">
                                <option *ngFor="let language of languages" [value]="language.code" [selected]="language.code === translate.currentLang">{{language.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- Qusetion Section -->
            <form [formGroup]="phase4_survey_form">
                <div *ngFor="let question of 'phase4.qust' | translate">
                    <div class="row">
                        <div class="col">
                            <div class="phase4_ques1_text">{{question.qustTitle}}</div>
                        </div>
                    </div>
                    <textarea *ngIf="question.inputType == 'input'" placeholder="Please write your answer here" formControlName="{{question.formControlName}}" class="phase4InputField" rows="6"></textarea>
                    <!-- Validating errors ------------------------->
                    <div *ngIf="question.formControlName== 'phase4Ques1'">
                        <div *ngIf="phase4Ques1?.invalid && (phase4Ques1?.dirty || phase4Ques1?.touched)" class="errorsBox">
                            <div *ngIf="phase4Ques1?.errors?.['required']" class="errorMsg">
                                Should not be null or empty
                            </div>
                            <div *ngIf="phase4Ques1?.errors?.['minlength']" class="errorMsg">
                                Should be minimum 5 characters length
                            </div>
                            <div *ngIf="phase4Ques1?.errors?.['pattern']" class="errorMsg">
                                Should be alphabets
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngFor="let opt of question.options; let i = index">
                        <div class="col">
                            <label *ngIf="question.inputType == 'radio'" class="phase4_educationContainer1">{{opt}}
                                <input type="radio" value="{{opt}}" name="{{question.formControlName}}" formControlName="{{question.formControlName}}">
                                <span class="phase4_checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
            <!-- Previous & next button -->
            <div class="row">
                <div class="col phase4nextBtnBox">
                    <button class="phase4_next_btn" [disabled]="!phase4_survey_form.valid" (click)="submitPhase4Form()" >Next</button>
                </div>
            </div>
        </div>
    </div>
</div>
