import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { specailTalentFileUpload } from '../model/specailTalent';
import { SurveyFormService } from '../survey-form.service';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from '../model/supported-languages';

@Component({
  selector: 'app-survey-phase3',
  templateUrl: './survey-phase3.component.html',
  styleUrls: ['./survey-phase3.component.css']
})
export class SurveyPhase3Component implements OnInit {

  aboutSurveyPhase3Form!: FormGroup;

  openSpecialTalentInput: boolean = false;
  selectSplTalentDoc: boolean = true;
  percentage: any;
  fileDocData: any;
  splTalentFile? : FileList;
  splTalentCurrentFile? : specailTalentFileUpload;

  aboutSurveyPhase3FormUpload(){
    this.aboutSurveyPhase3Form = this.formBuilder.group({
      afterClgWork: ['',[Validators.required]],
      govtExam: ['',[Validators.required]],
      jobAwareness: ['',[Validators.required]],
      workAndIntershipExp: ['',[Validators.required]],
      necessarySkills: ['',[Validators.required]],
      internetProfProspects: ['',[Validators.required]],
      iitMaterialUse: ['',[Validators.required]],
      cvKnowledge: ['',[Validators.required]],
      linkedIn: ['',[Validators.required]],
      specialTalent: ['']
    })
  }
  
  languages = SUPPORTED_LANGUAGES;

  spinner: boolean = true;

  constructor(private formBuilder: FormBuilder, private uploadService: SurveyFormService, public translate: TranslateService, private router: Router) {
    var language = JSON.parse(localStorage.getItem('surveyLanguage')!)
    translate.addLangs(['en', 'ta']);
    if(language) {
      translate.setDefaultLang(language);
    } else {
      translate.setDefaultLang('en')
    }
   }

  ngOnInit(): void {
    this.startSurveyAsNew();
    this.aboutSurveyPhase3FormUpload();
    // this.patchValueFromLocal();
    this.spinner = false;
  }

  patchValueFromLocal() {
    var phase3Form = JSON.parse(localStorage.getItem('surveyPhase3Form')!);
    if(phase3Form != null) {
      this.aboutSurveyPhase3Form.patchValue({
        afterClgWork: phase3Form.afterClgWork,
        govtExam:  phase3Form.govtExam,
        jobAwareness:  phase3Form.jobAwareness,
        workAndIntershipExp:  phase3Form.workAndIntershipExp,
        necessarySkills:  phase3Form.necessarySkills,
        internetProfProspects: phase3Form.internetProfProspects,
        iitMaterialUse:  phase3Form.iitMaterialUse,
        cvKnowledge:  phase3Form.cvKnowledge,
        linkedIn:  phase3Form.linkedIn,
      });

      if (phase3Form.specialTalent.fileName) {
        const dataTransfer = new ClipboardEvent('').clipboardData || new DataTransfer();
        dataTransfer.items.add(new File(['my File'], phase3Form.specialTalent.fileName));
        const inputElement: HTMLInputElement = document.getElementById('splTalentInput') as HTMLInputElement;
        inputElement.files = dataTransfer.files;
      } else if (phase3Form.specialTalent.fileName == null){
        this.aboutSurveyPhase3Form.patchValue({
          specialTalent : phase3Form.specialTalent
        })
      }
    }
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  startSurveyAsNew() {
    // Form 1
    var phase1Form = [];
    phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    // Form 2
    var phase2Form = [];
    phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    // Form 3
    var phase3Form = [];
    phase3Form = JSON.parse(localStorage.getItem('surveyPhase3Form')!);
    // Form 4
    var phase4Form = [];
    phase4Form = JSON.parse(localStorage.getItem('surveyPhase4Form')!);
    // Form 5
    var phase5Form = [];
    phase5Form = JSON.parse(localStorage.getItem('surveyPhase5Form')!);

    if (phase1Form == null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['TakeSurvey']) 
    } else if (phase1Form != null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['phase2']) 
    } else if (phase1Form != null && phase2Form != null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['phase3']) 
    } else if (phase1Form != null && phase2Form != null && phase3Form != null && phase4Form == null) {
      this.router.navigate(['phase4']) 
    } else if (phase1Form != null && phase2Form != null && phase4Form != null && phase4Form != null) {
      this.router.navigate(['phase5']) 
    }
  }

  submitPhase3(){
    if(this.openSpecialTalentInput == true){
      this.getUploadedFileData();
      if(this.fileDocData !== null){
        this.aboutSurveyPhase3Form.value["specialTalent"] = this.fileDocData;
      }
    }
    // Form 1
    var phase1Form = [];
    phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    // Form 2
    var phase2Form = [];
    phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    if (phase1Form != null && phase1Form != undefined && phase2Form != null && phase2Form != undefined) {
      this.uploadService.getPhase3Form(this.aboutSurveyPhase3Form.value)   
      this.router.navigate(['phase4']);
    }
    else {
      this.router.navigate(['TakeSurvey']).then(() => {
        alert("Please fill the survey step by step properly !")
      })
    }
  }

  checkFormValid() {
    if(this.openSpecialTalentInput == true && this.aboutSurveyPhase3Form.valid) {
      var fileData = this.uploadService.getSplTalentLocalData();
      if(fileData != undefined && fileData != null && fileData != '') {
        return false;
      } else {
        return true;
      }
    } else if(this.openSpecialTalentInput == false && this.aboutSurveyPhase3Form.valid) {
      return false;
    } else {
      return true;
    }
  }

  openSplTalent(){
    this.openSpecialTalentInput = true;
    this.removeFileData();
  }

  changeSplTalent(){
    this.openSpecialTalentInput = false;
    this.fileDocData = [];
    localStorage.removeItem('specailTalentDocument');
    this.selectSplTalentDoc = true;
  }

  specailTalentFile(event: any){
    document.getElementById('splTalentInput')!.style.color = "#5B5B5B";
    this.selectSplTalentDoc = false;
    this.splTalentFile = event.target.files;
    this.uploadMarkSheet();
  }

  uploadMarkSheet(): void{
    if(this.splTalentFile){
      const file: File | null = this.splTalentFile.item(0);
      if(file){
        this.splTalentCurrentFile = new specailTalentFileUpload(file);
        this.uploadService.pushSpecialTalentFileToStorage(this.splTalentCurrentFile).subscribe(
          percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
            this.aboutSurveyPhase3Form.get('specialTalent')?.clearValidators();
            this.aboutSurveyPhase3Form.get('specialTalent')?.updateValueAndValidity();
          },
          error => {
            console.log(error);
          }
        )
      }
    }

  }

  getUploadedFileData(){
    var fileData = this.uploadService.getSplTalentLocalData();
    console.log(fileData)
    this.fileDocData = [];
    this.fileDocData = {
      "fileName": fileData.proofFileName,
      "fileUrl": fileData.proofFileUrl
    }
  }

  removeFileData(){
    if(JSON.parse(localStorage.getItem('specailTalentDocument')!)){
      console.log('checked')
      this.aboutSurveyPhase3Form.get('specialTalent')?.clearValidators();
    }else {
      console.log('Not checked')
      this.aboutSurveyPhase3Form.controls['specialTalent'].setValidators([Validators.required])
    }
    this.aboutSurveyPhase3Form.get('specialTalent')?.updateValueAndValidity();
  }

  // Validation ---------------------------
  get afterClgWork(){
    return this.aboutSurveyPhase3Form.get('afterClgWork')
  }

  get govtExam(){
    return this.aboutSurveyPhase3Form.get('govtExam')
  }

  get jobAwareness(){
    return this.aboutSurveyPhase3Form.get('jobAwareness')
  }

  get workAndIntershipExp(){
    return this.aboutSurveyPhase3Form.get('workAndIntershipExp')
  }

  get necessarySkills(){
    return this.aboutSurveyPhase3Form.get('necessarySkills')
  }

  get internetProfProspects(){
    return this.aboutSurveyPhase3Form.get('internetProfProspects')
  }

  get iitMaterialUse(){
    return this.aboutSurveyPhase3Form.get('iitMaterialUse')
  }

  get cvKnowledge(){
    return this.aboutSurveyPhase3Form.get('cvKnowledge')
  }

  get linkedIn(){
    return this.aboutSurveyPhase3Form.get('linkedIn')
  }

  get specialTalent(){
    return this.aboutSurveyPhase3Form.get('specialTalent')
  }

}
