<div class="container mainContainer">
    <div class="row">
        <div class="col-12 whyRuralYouthHeadTextInSeperatePage">
            Why Rural Youth?
        </div>
    </div>
    <div class="row">
        <div class="col-12 whyRuralYouthBodyTextInSeperatePage">
        Rural youth strive to be productive, connected individuals who are in control of their own path. As a
        result, these aspects are critical to the development of rural youth. <br> <br>
        Several factors influence young people's potential to become productive adults. First and foremost, where
        they live is vital; many social and economic factors might enhance or inhibit their advancement. Second,
        their cognitive and non-cognitive abilities determine their output. <br> <br>
        Governments must ensure that societal institutions and attitudes aid rather than hinder the advancement of
        rural youngsters. This is especially true for young rural women, who are often left behind. Governments must
        also bring together all educational stakeholders. This will ensure that adolescents get the knowledge and
        skills need to become more productive. <br> <br>
        Rural kids can become more productive as their connections to people, markets, services, ideas, and
        information improve. Everyone's chances expand when rural areas have improved information and transportation
        access to markets, including rural youth's. They can also use increased connectivity to grow and reinforce
        their social and human capital, improve their abilities, and boost their self-confidence. As a result, they
        feel more empowered and productive. <br> <br>
        In comparison to adults and urban youth, rural youth have less control over their lives. This is especially
        true for young women, who may be bound by their family's and society's expectations. Similarly, rural youth
        who are subjected to several layers of exclusion may find it difficult to harness today's quick pace of
        change in order to strengthen their freedom. <br> <br>
        It's vital to pay close attention to the environment in which rural youth live. What variables influence
        their ability to be productive and connected in their national, local, and family environments? Second, what
        are the main difficulties that young people face as they transition from youth to adulthood and increased
        independence? <br> <br>
        To address the challenges that rural youth confront, an effective rural youth programme should be in place.
        <br> <br>
        We at Rural Youth of India are working towards the same goal, leaving no stone unturned in identifying areas
        for improvement for rural youth, raising all necessary awareness for their future development, and finally
        providing them with appropriate training so that they can leverage their skills in the long run and live a
        better life. <br> <br>

    </div>
</div>
</div>
