import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  Hideheader = new EventEmitter(); 
  ShowHeader = new EventEmitter();

  private sendSubject = new Subject<any>();

  constructor() { }

  HideheaderButton() {    
    this.Hideheader.emit();    
  } 

  ShowheaderButton() {    
    this.ShowHeader.emit();    
  } 

  sendEvent() {
    this.sendSubject.next('value')
  }

  getEvent(): Observable<any>{
    return this.sendSubject.asObservable();
  }
}
