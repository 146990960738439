<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container-fluid p-0">
    <div class="jumbotron jumbotron-fluid m-0">
        <video autoplay [muted]="'muted'" loop class="video-bg">
            <source src="https://firebasestorage.googleapis.com/v0/b/rural-youth-2f166.appspot.com/o/videos%2FRY3__Home.mp4?alt=media&token=739cc5c6-8714-4700-b4a9-40559bb711ac" type="video/mp4"
            disablepictureinpicture loop controls controlsList="nodownload nofullscreen noplaybackrate">
            Your browser does not support the video tag.
        </video>
    </div>
</div>

<div class="container mainContainerOne p-0">    
    <div class="row g-0">
        <div class="col-12">
            <div class="ruralYouthHeadText">Rural Youth - RY</div>
            <div class="ruralYouthBodyText">
                We're a non-profit on a mission to empower India's rural youth. We boost awareness about opportunities
                and technology, enhance employability, and offer training to pursue dreams. Join us and be part of the
                change today!
            </div>
            <!-- <div class="ruralYouthBodyText">
                <div class="ruralYouthBodyTextNotePointHeading">
                    Rural Youth of India is a non-profit organization dedicated to empowering India's youth.
                    Three of our key goals are: <br>
                </div>
                <img src="../../assets/icons/home page bullet icon.png" alt="bulletsPointIcon" class="bulletsPointIcon">
                To raise awareness about the opportunities and technology available in our country, but which our
                rural youngsters are unaware of. <br>
                <img src="../../assets/icons/home page bullet icon.png" alt="bulletsPointIcon" class="bulletsPointIcon">
                To increase the employability of young people so that they can live better lives and meet their
                requirements. <br>
            </div> -->
        </div>
        <!-- <div class="col-xl-6 col-lg-6 col-sm-6 d-flex justify-content-end Ry_video_center">
            <video
                src="https://firebasestorage.googleapis.com/v0/b/rural-youth-2f166.appspot.com/o/videos%2FRY3%201.mp4?alt=media&token=50c9382b-922e-4cee-86b6-141be9abdbfc"
                width="707px" height="424px" type="video/mp4" class="homePageVideo" autoplay [muted]="'muted'"
                disablepictureinpicture loop controls controlsList="nodownload nofullscreen noplaybackrate"
                (click)="playPauseVideo()" #homePageVideo></video>
        </div> -->
        <!-- <div class="col-xxl-6 col-12 ruralYouthBodyText externalContentRuralYouth">
            <img src="../../assets/icons/home page bullet icon.png" alt="bulletsPointIcon" class="bulletsPointIcon">
            We also want to provide training to
            rural youth so that they can pursue their varied interests. They can let us know what they're interested in,
            and we'll provide training in that area.
        </div> -->
    </div>
    <div class="row g-0">
        <div class="col-12 whyRuralYouthHeadText">
            Unleashing the Potential of Rural Youth
        </div>
    </div>
    <div class="row g-0">
        <div class="col-12 whyRuralYouthBodyText">
            At Rural Youth of India, we believe that every young person in rural areas deserves the opportunity to reach
            their full potential. That's why we are dedicated to providing the skills, resources, and support necessary
            for rural youth to succeed in their personal and professional lives. Join us in our mission to create a
            brighter future for rural youth and empower the next generation of rural leaders.
            <a routerLink="/whyRuralYouth" routerLinkActive="active">Read More...</a>
        </div>
    </div>
    <div class="row cartoonImageContainer lap_responsive g-0">
        <div class="col-xl-4 col-lg-4 col-sm-4 d-flex justify-content-center p-0">
            <img class="firstCartoonImage" src="assets/cartoonImages/b-1.svg" alt="First image of cartoon">
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 d-flex justify-content-center p-0">
            <img class="secondCartoonImage" src="assets/cartoonImages/Group 272.svg" alt="second image of cartoon">
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 d-flex justify-content-center p-0">
            <img class="thirdCartoonImage" src="assets/cartoonImages/b.svg" alt="third image of cartoon">
        </div>
    </div>
    <div class="row cartoonImageContainer Mob_responsive g-0">
        <div class="col-12 d-flex justify-content-center">
            <img class="firstCartoonImage" src="assets/cartoonImages/b-1.svg" alt="First image of cartoon">
            <div class="Career_Development_text">
                Career Development
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <img class="secondCartoonImage" src="assets/cartoonImages/Group 272.svg" alt="second image of cartoon">
            <div class="Raising_Awarness_text">
                Raising Awarness
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <img class="thirdCartoonImage" src="assets/cartoonImages/b.svg" alt="third image of cartoon">
            <div class="Research_Work_text">
                Up-Skilling
            </div>
        </div>
    </div>
    <div class="row cartoonImageTextContainer lap_responsive">
        <div class="col-xl-4 col-lg-4 col-sm-4 d-flex justify-content-center Career_Development_text">
            Career Development
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 d-flex justify-content-center Raising_Awarness_text">
            Raising Awarness
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 d-flex justify-content-center Research_Work_text">
            Up-Skilling
        </div>
    </div>
    <!-- count section -->
    <div class="row AchievementsCountBoxes">
        <div class="AchievementsTextInCountBox">
            Achievements
        </div>
        <div class="col-3 benefitCountBoxOne">
            <div class="totalCollegesNumber">
                {{totalColleges}}
            </div>
            <div class="totalCollegesText">
                Total Colleges
            </div>
        </div>
        <div class="col-3 benefitCountBoxTwo">
            <div class="totalWorkShopsNumber">
                {{totalWorkShops}}
            </div>
            <div class="totalWorkshopText">
                Workshops Conducted
            </div>
        </div>
        <div class="col-3 benefitCountBoxThree">
            <div class="totalPeopleBenefitedNumber">
                {{totalPeopleBenefited}}
            </div>
            <div class="totalPeopleBenifittedText">
                People Benefitted
            </div>
        </div>
        <div class="col-3 benefitCountBoxFour">
            <div class="totalJobOfferReceivedNumber">
                {{totalJobOfferReceived}}
            </div>
            <div class="totalJobOfferReceivedText">
                Total Job Offers Received
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="purpleContainer">
        <div class="container purpleContainermobile">
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-lg-8 col-sm-12">
                    <div class="RYSurveyHeadText">
                        Help Empower Rural Youth of India!
                    </div>
                    <div class="RYSurveyBodyText">
                        <div class="externalContentSurveySubHeadTop">
                            Struggling with issues like unemployment and poverty, India's rural youth need your support. By
                            participating in our survey, you can:
                        </div> <br> <br>
                        <div class="externalContentSurveySubHead">
                            Unlock Potential: <span>Raise awareness about opportunities.</span>
                            <br> <br>
                        </div>
                        <div class="externalContentSurveySubHead">
                            Enhance Lives: <span>Improve employability and well-being.</span>
                            <br> <br>
                        </div>
                        <div class="externalContentSurveySubHead">
                            Fulfill Dreams: <span>Guide youth towards their passions.</span>
                            <br> <br>
                        </div>
                    </div>
                </div>
                <div
                    class="col-xxl-4 col-xl-4 col-lg-4 col-sm-12 col-xs-8 purbleContainerCartoon d-flex justify-content-center align-items-center">
                    <img class="purpleContainerCartoonImage" src="assets/cartoonImages/Frame.svg" alt="Cartoon image"
                        class="w-100 purpleContainerCartoonImageRightSide">
                </div>
                <!-- <div class="RYSurveyBodyText externalContentForPurpleContainer">
                    <u> Three of our main objectives are: <br> </u>
                    <img src="../../assets/icons/home_page_bullets_icon_2" alt="" class="homePageBulletIcon2">
                    To increase awareness about the potential and technologies that rural youth are unaware of <br>
                    <img src="../../assets/icons/home_page_bullets_icon_2" alt="" class="homePageBulletIcon2">
                    To improve young people's employability so that they can live better lives and meet their basic
                    needs
                    <br>
                    <img src="../../assets/icons/home_page_bullets_icon_2" alt="" class="homePageBulletIcon2">
                    We provide the training to rural youth so they can pursue their interests and passion. They can
                    let us know what they wish to learn and acquire, and we'll provide proper guidance to make their
                    dream into reality <br> <br>

                    Please participate in this survey to realize our target objectives. We need your support !
                </div> -->
                <div class="RYSurveyBodyText RYSurveyBodyTextEnd externalContentForPurpleContainer">
                    Join Us Now! Your input can make a real difference. Take the survey and be part of the change!
                </div>
                <div class="col-12 startSurveyBtnContainer">
                    <button class="startSurveyBtn" (click)="startSurvey()">Start Survey</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container becomeVolunteerContainer">
    <!-- <div class="AchievementsTextInCountBox">
        Achievements
    </div> -->
    <div class="row">
        <div class="col-12 becomeVolunteerHeadText">
            Become a Volunteer
        </div>
        <div class='col-12 becomeVolunteerBodyText'>
            Join us in our mission to empower the next generation of rural leaders and make a meaningful impact in the
            lives of rural youth. Sign up to become a volunteer with Rural Youth of India today! <a
                routerLink="/becomeVolunteerPage" routerLinkActive="active">Read more...</a>
        </div>
        <!-- <div class="row applyVolunteerBtnContainer">
            <button class="applyVolunteerBtn" type="button">Apply for volunteer</button>
        </div> -->
    </div>
    <div class="row">
        <div class="col-12 photoGalleryHeadText">
            Photo gallery
        </div>
        <div class="col-12 photoGalleryBodyText">
            Please have a look of our Gallery. In this part, we'll post photos and videos from the events we'll be
            hosting, as well as documented moments that we think are worth preserving.
            Take a look at our gallery to see how far Rural Youth of India has come.
            For any suggestions, feel free to message us at any point of time. <a routerLink="/imageGallery"
                routerLinkActive="active">See more...</a>
        </div>

        <div class="row lap_responsive">
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item  active">
                        <div class="firstPageOfCorousel">
                            <img src="assets/images/RY (1).jpg" class="slider-image-Bootstrap" alt="">
                            <img src="assets/images/RY (2).jpg" class="slider-image-Bootstrap" alt="">
                            <img src="assets/images/RY (3).jpg" class="slider-image-Bootstrap" alt="">
                            <img src="assets/images/RY_1_4.jpg" class="slider-image-Bootstrap" alt="">
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="secondPageOfCorousel">
                            <img src="assets/images/RY (4).jpg" class="slider-image-Bootstrap" alt="">
                            <img src="assets/images/RY (5).jpg" class="slider-image-Bootstrap" alt="">
                            <img src="assets/images/RY (6).jpg" class="slider-image-Bootstrap" alt="">
                            <img src="assets/images/RY_2_4.jpg" class="slider-image-Bootstrap" alt="">
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="thirdPageOfCorousel">
                            <img src="assets/images/RY (7).jpg" class="slider-image-Bootstrap" alt="">
                            <img src="assets/images/RY (8).jpg" class="slider-image-Bootstrap" alt="">
                            <img src="assets/images/RY_3_3.jpg" class="slider-image-Bootstrap" alt="">
                            <img src="assets/images/RY_3_4.jpg" class="slider-image-Bootstrap" alt="">
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev">
                    <img class="pre-btnImage" src="assets/icons/chevron-left-solid.svg">
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                    data-bs-slide="next">
                    <img class="nxt-btnImage" src="assets/icons/chevron-right-solid.svg">
                </button>
            </div>
        </div>
        <div class="row Mob_responsive mobileViewSliderCourousel">
            <div class="imageSlider">
                <div class="sliderImage-container">
                    <div class="image-card">
                        <div class="slider-image">
                            <img src="assets/images/RY (1).jpg" class="slider-thumb" alt="">
                        </div>
                    </div>
                    <div class="image-card">
                        <div class="slider-image">
                            <img src="assets/images/RY (2).jpg" class="slider-thumb" alt="">
                        </div>
                    </div>
                    <div class="image-card">
                        <div class="slider-image">
                            <img src="assets/images/RY (3).jpg" class="slider-thumb" alt="">
                        </div>
                    </div>
                    <div class="image-card">
                        <div class="slider-image">
                            <img src="assets/images/RY (4).jpg" class="slider-thumb" alt="">
                        </div>
                    </div>
                    <div class="image-card">
                        <div class="slider-image">
                            <img src="assets/images/RY (5).jpg" class="slider-thumb" alt="">
                        </div>
                    </div>
                    <div class="image-card">
                        <div class="slider-image">
                            <img src="assets/images/RY (6).jpg" class="slider-thumb" alt="">
                        </div>
                    </div>
                    <div class="image-card">
                        <div class="slider-image">
                            <img src="assets/images/RY (7).jpg" class="slider-thumb" alt="">
                        </div>
                    </div>
                    <div class="image-card">
                        <div class="slider-image">
                            <img src="assets/images/RY (8).jpg" class="slider-thumb" alt="">
                        </div>
                    </div>
                    <div class="image-card">
                        <div class="slider-image slider-image-last">
                            <img src="assets/images/RY (9).jpg" class="slider-thumb" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 successStoryHeadText">
            Success stories
        </div>
        <div class="col-12 successStoryBodyText">
            We are constantly connecting with rural youth and learning about their challenges. By doing so, we will have
            the opportunity to learn about a spectrum of motivational stories from young people all over the country who
            will unveil their worth, which was previously hidden. This section is basically for sharing success stories
            of rural youths who are breaking all stereotypes and moving forward in their career with us! <a
                href="/successStories">Read more...</a>
        </div>
    </div>
    <div class="row newsPannelTable lap_responsive">
        <div class="col-4 RYNewsPannel">
            <div class="successStoryTopPart">
                <div class="d-flex justify-content-center">
                    <img class="col-12 newsPannelImage " src="assets/images/RYNewsImg1.jpg" alt="news Header image">
                </div>
                <div class="col-12 newsHeadlineText">
                    APJ Abdul kalam
                </div>
                <div class="12 newsBodyContent">
                    Kalam was the 11th President of India, serving from 2002 to 2007. Born on October 15, 1931, in
                    Rameswaram, Tamil Nadu, he was the son of a boat owner and had a humble childhood.
                </div>
            </div>
            <div class="d-flex justify-content-center successStoryBottomPart">
                <button class="RYNewspannelBtn" (click)="storeSuccessStoryPersonNameInLocal('APJ Abdul kalam')">Read
                    more</button>
            </div>
        </div>
        <div class="col-4 RYNewsPannel RYNewsPannel_center">
            <div class="successStoryTopPart">
                <div class="d-flex justify-content-center">
                    <img class="col-12 newsPannelImage " src="assets/images/RYNewsImg2.jpg" alt="news Header image">
                </div>
                <div class="col-12 newsHeadlineText">
                    MS Dhoni
                </div>
                <div class="12 newsBodyContent">
                    Mahendra Singh Dhoni, commonly known as MS Dhoni, is a former Indian cricketer who captained the
                    Indian national team from 2007 to 2016. He was born on July 7, 1981, in Ranchi, Jharkhand, and grew
                    up in a small village in the region.
                </div>
            </div>
            <div class="d-flex justify-content-center successStoryBottomPart">
                <button class="RYNewspannelBtn" (click)="storeSuccessStoryPersonNameInLocal('MS Dhoni')">Read
                    more</button>
            </div>
        </div>
        <div class="col-4 RYNewsPannel">
            <div class="successStoryTopPart">
                <div class="d-flex justify-content-center">
                    <img class="col-12 newsPannelImage " src="assets/images/successStories/tNatarajan.jpg" alt="news Header image">
                </div>
                <div class="col-12 newsHeadlineText">
                    T. Natarajan
                </div>
                <div class="12 newsBodyContent">
                    Thangarasu Natarajan, commonly known as T. Natarajan, is an Indian cricketer who gained prominence
                    for his left-arm medium-fast bowling. Born on May 27, 1991, in Salem, Tamil Nadu, Natarajan's
                    journey to professional cricket is quite inspiring.
                </div>
            </div>
            <div class="d-flex justify-content-center successStoryBottomPart">
                <button class="RYNewspannelBtn" (click)="storeSuccessStoryPersonNameInLocal('T. Natarajan')">Read
                    more</button>
            </div>
        </div>
    </div>
    <div class="row newsPannelTable Mob_responsive SuccessStoryBody">
        <div class="storybody">
            <div class="RYNewsPannel">
                <div class="d-flex justify-content-center">
                    <img class="col-12 newsPannelImage " src="assets/images/RYNewsImg1.jpg" alt="news Header image">
                </div>
                <div class="col-12 newsHeadlineText">
                    APJ Abdul kalam
                </div>
                <div class="12 newsBodyContent">
                    Kalam was the 11th President of India, serving from 2002 to 2007. Born on October 15, 1931, in
                    Rameswaram, Tamil Nadu, he was the son of a boat owner and had a humble childhood.
                </div>
                <div class="d-flex justify-content-center">
                    <button class="RYNewspannelBtn" (click)="storeSuccessStoryPersonNameInLocal('APJ Abdul kalam')">Read
                        more</button>
                </div>
            </div>
            <div class="RYNewsPannel RYNewsPannel_center">
                <div class="d-flex justify-content-center">
                    <img class="col-12 newsPannelImage " src="assets/images/RYNewsImg2.jpg" alt="news Header image">
                </div>
                <div class="col-12 newsHeadlineText">
                    MS Dhoni
                </div>
                <div class="12 newsBodyContent">
                    Mahendra Singh Dhoni, commonly known as MS Dhoni, is a former Indian cricketer who captained the
                    Indian national team from 2007 to 2016. He was born on July 7, 1981, in Ranchi, Jharkhand, and grew
                    up in a small village in the region.
                </div>
                <div class="d-flex justify-content-center">
                    <button class="RYNewspannelBtn" (click)="storeSuccessStoryPersonNameInLocal('MS Dhoni')">Read
                        more</button>
                </div>
            </div>
            <div class="RYNewsPannel">
                <div class="d-flex justify-content-center">
                    <img class="col-12 newsPannelImage " src="assets/images/successStories/tNatarajan.jpg" alt="news Header image">
                </div>
                <div class="col-12 newsHeadlineText">
                    T. Natarajan
                </div>
                <div class="12 newsBodyContent">
                    Thangarasu Natarajan, commonly known as T. Natarajan, is an Indian cricketer who gained prominence
                    for his left-arm medium-fast bowling. Born on May 27, 1991, in Salem, Tamil Nadu, Natarajan's
                    journey to professional cricket is quite inspiring.
                </div>
                <div class="d-flex justify-content-center">
                    <button class="RYNewspannelBtn" (click)="storeSuccessStoryPersonNameInLocal('T. Natarajan')">Read
                        more</button>
                </div>
            </div>
        </div>
    </div>
</div>