import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  spinner: boolean = true;

   @ViewChild('aboutVideo') myVideo!: ElementRef;
  constructor() { }

  ngOnInit(): void {
    this.spinner = false
  }

  playPauseVideo() {
    if (this.myVideo.nativeElement.paused)
    this.myVideo.nativeElement.play();
    else
    this.myVideo.nativeElement.pause();
  }

}
