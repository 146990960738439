import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { http_service } from '../../../service.url';

@Injectable({
  providedIn: 'root'
})
export class RyMailServiceService {

  BaseUrl = http_service.baseUrl;

  constructor(private httpClient: HttpClient) { }

  public userCommentsMailFunction(object: any) {
    return this.httpClient.post(this.BaseUrl + 'ry-mail/userCommentsForRy', object);
  }

  public surveyCompleteMailFunction(object: any) {
    return this.httpClient.post(this.BaseUrl + 'ry-mail/surveyFinishedMail', object);
  }
}
