import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-brief-success-story',
  templateUrl: './brief-success-story.component.html',
  styleUrls: ['./brief-success-story.component.css']
})
export class BriefSuccessStoryComponent implements OnInit {

  nameOfThePerson : any;
  spinner: boolean = true;
  constructor(private router: Router) { 
    this.nameOfThePerson = localStorage.getItem('personNameForArticle');
    console.log(this.nameOfThePerson);
  }

  ngOnInit(): void {
    this.spinner = false
  }

  
  redirectToStoriesPage() {
    this.router.navigate(['successStories']);
  }
}
