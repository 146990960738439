import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-takesurvey',
  templateUrl: './takesurvey.component.html',
  styleUrls: ['./takesurvey.component.css']
})
export class TakesurveyComponent implements OnInit {

  enableStartBtn: boolean = false;

  @ViewChild('surveyVideo') mySurveyVideo!: ElementRef;

  surveyRedirection() {
    var consent: any;
    consent = localStorage.getItem('consent');
    // Form 1
    var phase1Form = [];
    phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    // Form 2
    var phase2Form = [];
    phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    // Form 3
    var phase3Form = [];
    phase3Form = JSON.parse(localStorage.getItem('surveyPhase3Form')!);
    // Form 4
    var phase4Form = [];
    phase4Form = JSON.parse(localStorage.getItem('surveyPhase4Form')!);
    // Form 5
    var phase5Form = [];
    phase5Form = JSON.parse(localStorage.getItem('surveyPhase5Form')!);

    
    if(consent == 'true' && phase1Form == null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['/phase1']).then(() => {
        alert("Consent is completed, lets start from phase 1");
      })
    }
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.surveyRedirection();
    if(window.innerWidth <= 575) {
      console.log(window.innerWidth)
      window.scrollTo(0, 2000);
    }
  }

  onChangeConsent() {
    if(this.enableStartBtn == false) {
      this.enableStartBtn = true;
    } else {
      this.enableStartBtn = false;
    }
  }

  // play or pause video =-------------------------
  playPauseVideo() {
    if (this.mySurveyVideo.nativeElement.paused)
    this.mySurveyVideo.nativeElement.play();
    else
    this.mySurveyVideo.nativeElement.pause();
  }

  setConsent() {
    localStorage.setItem('consent', "true");
    this.enableStartBtn = false;
    localStorage.setItem('surveyLanguage', JSON.stringify("en"));
  }

}