import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SurveyFormService } from '../survey-form.service';
import { Route, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from '../model/supported-languages';

@Component({
  selector: 'app-survey-phase2',
  templateUrl: './survey-phase2.component.html',
  styleUrls: ['./survey-phase2.component.css']
})
export class SurveyPhase2Component implements OnInit {

  checkedHobbiesList: any[]= [];
  otherHobbies:boolean = false;
  otherHobbyInput = '';
  othersHobbyInput: boolean = false
  aboutSurveyPhase2Form! : FormGroup;
  languageChange: boolean = false;

  aboutSurveyPhase2FormUpload(){
    this.aboutSurveyPhase2Form = this.formBuilder.group({
      hobbiesList: this.formBuilder.array([], [Validators.required]),
      internetConnection: ['', [Validators.required]],
      phoneApp: [''],
      socialMediaUses: ['', [Validators.required]],
      fundingForStudies: ['', [Validators.required]],
      specialActivity: ['', [Validators.required]],
      firstGraduate: ['', [Validators.required]],
      govtEmployee: ['', [Validators.required]]
    })
  }

  languages = SUPPORTED_LANGUAGES;

  spinner: boolean = true;

  otherActivityInput = '';
  activityInput = '';
  otherActivity: boolean = false;

  constructor(private formBuilder: FormBuilder, private uploadService: SurveyFormService, public translate: TranslateService, private router: Router) { 
    var language = JSON.parse(localStorage.getItem('surveyLanguage')!)
    translate.addLangs(['en', 'ta']);
    if(language) {
      translate.setDefaultLang(language);
    } else {
      translate.setDefaultLang('en')
    }
    if(language == 'ta') {
      this.languageChange = true;
    }
    else {
      this.languageChange = false;
    }
  }

  ngOnInit(): void {
    this.startSurveyAsNew();
    this.aboutSurveyPhase2FormUpload();
    this.spinner = false;
    // this.patchValueFromLocal();
  }

  patchValueFromLocal() {
    var phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    if(phase2Form != null) {
      this.aboutSurveyPhase2Form.patchValue(phase2Form)
    }
  }

  switchLang(lang: string) {
    // if(lang == 'ta') {
    //   this.languageChange = true;
    // }
    // else {
    //   this.languageChange = false;
    // }
  }

  getLanguage() {
    var language = JSON.parse(localStorage.getItem('surveyLanguage')!);
    this.translate.use(language)
  }

  startSurveyAsNew() {   
    // Form 1
    var phase1Form = [];
    phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    // Form 2
    var phase2Form = [];
    phase2Form = JSON.parse(localStorage.getItem('surveyPhase2Form')!);
    // Form 3
    var phase3Form = [];
    phase3Form = JSON.parse(localStorage.getItem('surveyPhase3Form')!);
    // Form 4
    var phase4Form = [];
    phase4Form = JSON.parse(localStorage.getItem('surveyPhase4Form')!);
    // Form 5
    var phase5Form = [];
    phase5Form = JSON.parse(localStorage.getItem('surveyPhase5Form')!);

    if (phase1Form == null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['TakeSurvey']) 
    } else if (phase1Form != null && phase2Form == null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['phase2']) 
    } else if (phase1Form != null && phase2Form != null && phase3Form == null && phase4Form == null) {
      this.router.navigate(['phase3']) 
    } else if (phase1Form != null && phase2Form != null && phase3Form != null && phase4Form == null) {
      this.router.navigate(['phase4']) 
    } else if (phase1Form != null && phase2Form != null && phase4Form != null && phase4Form != null) {
      this.router.navigate(['phase5']) 
    }
  }

  submitPhase2(){
    if(this.otherHobbyInput !== null && this.otherHobbyInput !== ''){
      this.checkedHobbiesList.push(this.otherHobbyInput)
    }
    this.aboutSurveyPhase2Form.value['hobbiesList'] = this.checkedHobbiesList;

    if(this.otherActivityInput != null && this.otherActivityInput != '') {
      this.aboutSurveyPhase2Form.value['specialActivity'] = this.otherActivityInput;
    } else {
      this.aboutSurveyPhase2Form.value['specialActivity'] = this.activityInput;
    }
    
    var phase1Form = [];
    phase1Form = JSON.parse(localStorage.getItem('surveyPhase1Form')!);
    if (phase1Form != null && phase1Form != undefined) {
      this.uploadService.getPhase2Form(this.aboutSurveyPhase2Form.value);    
      this.router.navigate(['phase3']);
    }
    else {
      this.router.navigate(['TakeSurvey']).then(() => {
        alert("Please fill the survey step by step properly !")
      })
    }
  }

  checkOtherHobby(e: any){
    this.otherHobbies = true;
    if(this.otherHobbyInput !== null && this.otherHobbyInput !== ''){
      this.aboutSurveyPhase2Form.get('hobbiesList')?.clearValidators();
    }else if(this.otherHobbyInput == ''){
      this.aboutSurveyPhase2Form.controls['hobbiesList'].setValidators([Validators.required]);
    }
    this.aboutSurveyPhase2Form.get('hobbiesList')?.updateValueAndValidity();
  }

  onCheckboxChange(e: any) {
    const checkArray: FormArray = this.aboutSurveyPhase2Form.get('hobbiesList') as FormArray;
    if((e.target.value != 'Others:') && (e.target.value != 'மற்றவை:')) {
      if(e.target.checked) {
        checkArray.push(new FormControl(e.target.value));
        this.checkedHobbiesList.push(e.target.value)
      }else{
        let i: number = 0;
        checkArray.controls.forEach((item: any) => {
          if (item.value == e.target.value) {
            checkArray.removeAt(i);
            this.checkedHobbiesList.splice(i,1)
            return;
          }
          i++;
        });
      }
    }
    else {
      if(e.target.checked) {
        this.othersHobbyInput = true;
      }
      else {
        this.othersHobbyInput = false;
        this.otherHobbyInput = '';
        this.aboutSurveyPhase2Form.controls['hobbiesList'].setValidators([Validators.required]);
        this.aboutSurveyPhase2Form.get('hobbiesList')?.updateValueAndValidity();
      }
    }
  }

  checkOtherActivity(e: any) {
    this.otherActivity = true;
    if(this.otherActivityInput !== null && this.otherActivityInput !== ''){
      this.aboutSurveyPhase2Form.get('specialActivity')?.clearValidators();
    }else if(this.otherActivityInput == ''){
      this.aboutSurveyPhase2Form.controls['specialActivity'].setValidators([Validators.required]);
    }
    this.aboutSurveyPhase2Form.get('specialActivity')?.updateValueAndValidity();
  }

  onChangeActivityRadio(e: any, val: any) {
    if(val == 'specialActivity') {
      if((e.target.value == 'Others:') || (e.target.value == 'மற்றவை:')) {
        if(e.target.checked) {
          this.otherActivity = true;
          this.activityInput = '';
          this.aboutSurveyPhase2Form.controls['specialActivity'].setValidators([Validators.required]);
          this.aboutSurveyPhase2Form.get('specialActivity')?.updateValueAndValidity();
        }
      } else {
        this.otherActivity = false;
        this.otherActivityInput = '';
        this.activityInput = e.target.value;
        this.aboutSurveyPhase2Form.get('specialActivity')?.clearValidators();
        this.aboutSurveyPhase2Form.get('specialActivity')?.updateValueAndValidity();
      }
    }
  }

  // Validation -------------------------------
  get hobbiesList(){
    return this.aboutSurveyPhase2Form.get('hobbiesList')
  }

  get internetConnection(){
    return this.aboutSurveyPhase2Form.get('internetConnection')
  }

  get phoneApp(){
    return this.aboutSurveyPhase2Form.get('phoneApp')
  }

  get socialMediaUses(){
    return this.aboutSurveyPhase2Form.get('socialMediaUses')
  }

  get fundingForStudies(){
    return this.aboutSurveyPhase2Form.get('fundingForStudies')
  }

  get specialActivity(){
    return this.aboutSurveyPhase2Form.get('specialActivity')
  }

  get firstGraduate(){
    return this.aboutSurveyPhase2Form.get('firstGraduate')
  }

  get govtEmployee(){
    return this.aboutSurveyPhase2Form.get('govtEmployee')
  }

}