import { Component } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { HeaderService } from "./service/header.service";
import { SurveyFormService } from './survey-form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  progress: number = 100;

  enableProgress: boolean = false;

  startTime!: Date;
  endTime!: Date;
  elapsedTime: any;
  timerInterval: any; // Will hold the setInterval reference

  academicEventSubscription!: Subscription;

  newsLetter: boolean = false;
  getEventSubscription!: Subscription;
  
  constructor (private router: Router, private header : HeaderService, private surveyService: SurveyFormService){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateProgress();
      }
    });
    this.academicEventSubscription = this.surveyService.timerGetEvent().subscribe( ()=> {
      this.stopTimer();
    })
    this.getEventSubscription = this.header.getEvent().subscribe(() => {
      this.showSuccessMsg()
    })
  }

  onActivate(event: any) {
    window.scroll(0, 0)
    if(this.router.url === "/TakeSurvey" || this.router.url === "/phase1" || this.router.url === "/phase2" || this.router.url === "/phase3" || this.router.url === "/phase4" || this.router.url === "/phase5"){
      this.header.HideheaderButton();
    } else {
      this.header.ShowheaderButton();
    }
  }

  showSuccessMsg() {
    this.newsLetter = true;
    setTimeout(() => {
      this.newsLetter = false;
    }, 5000)
  }

  closeMsg() {
    this.newsLetter = false;
  }

  // progress bar loading
  updateProgress() {
    const currentRoute = this.router.url;
    let progressValue = 100;

    if(currentRoute === '/phase1' || currentRoute === '/phase2' || currentRoute === '/phase3' || currentRoute === '/phase4' || currentRoute === '/phase5') {
      const storedStartTime = localStorage.getItem('formTimerStartTime');
      if (storedStartTime) {
        this.startTime = new Date(storedStartTime);
        this.startTimer();
      } else {
        this.startTime = new Date();
        this.startTimer();
        localStorage.setItem('formTimerStartTime', this.startTime.toString());
      }
    }

    if (currentRoute === '/phase1') {
      progressValue = 2;
      this.enableProgress = true;      
    } else if (currentRoute === '/phase2') {
      progressValue = 25;
      this.enableProgress = true;
    } else if (currentRoute === '/phase3') {
      progressValue = 50;
      this.enableProgress = true;
    } else if (currentRoute === '/phase4') {
      progressValue = 75;
      this.enableProgress = true;
    } else if (currentRoute === '/phase5') {
      progressValue = 90;
      this.enableProgress = true;
    } else {
      this.enableProgress = false;
    }

    this.surveyService.setProgress(progressValue);
    this.progress = progressValue;
  }

  // Timer for survey
  startTimer() {
    this.timerInterval = setInterval(() => {
      this.updateElapsedTime();
    }, 1000); // Update every second
  }

  stopTimer() {
    clearInterval(this.timerInterval);
    this.endTime = new Date();
    localStorage.setItem('overAllTimeForSurvey', this.elapsedTime);
  }

  updateElapsedTime() {
    const currentTime = new Date();
    const elapsedMilliseconds = currentTime.getTime() - this.startTime.getTime();
  
    const seconds = Math.floor((elapsedMilliseconds / 1000) % 60);
    const minutes = Math.floor((elapsedMilliseconds / 1000 / 60) % 60);
    const hours = Math.floor(elapsedMilliseconds / 1000 / 3600);
  
    this.elapsedTime = `${this.formatTimeSegment(hours)}:${this.formatTimeSegment(minutes)}:${this.formatTimeSegment(seconds)}`;
  }

  formatTimeSegment(segment: number): string {
    return segment < 10 ? `0${segment}` : `${segment}`;
  }

}
