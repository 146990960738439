<app-spinner *ngIf="this.spinner"></app-spinner>
<div class="container surveyPhase2MainBox animate__animated animate__fadeInDown">
<!-- About the survey phase 2 ------------------------------->
    <div class="phase2ContentMainBox">
    <!-- Phase 2 Heading Box -------------------------------->
        <div class="phase2HeadingBox">
            <div class="phase2Heading">{{'phase2.title' | translate}}</div>
            <div class="phase2SubHeading">{{'phase2.noOfPhase' | translate}}</div>
        </div>
    <!-- Choose language Box ------------------------------->
        <!-- <div class="chooseSurveyLangBox">
            <div class="chooseLangHeading">Choose survey language:</div>
            <div class="selectLang">
                <select class="chooseLang" #selectedLang (change)="switchLang(selectedLang.value)" style="pointer-events: none;">
                    <option *ngFor="let language of languages" [value]="language.code" [selected]="language.code === translate.currentLang">{{language.name}}</option>
                </select>
            </div>
        </div> -->
    <!-- Form group phase 2 ------------------------------->
        <form [formGroup]="aboutSurveyPhase2Form">
        <!-- question --------------------------------->
        <div class="hobbiesBox">
            <div class="interentMainBox" *ngFor="let question of 'phase2.qust' | translate; let first= first">
                <div class="internetHeading">{{question.qustTitle}}</div>
                <div class="internetBox" *ngFor="let opt of question.options; let i = index; let last = last">
                    <div *ngIf="question.inputType == 'checkBox'" class="selectHobbyBox">
                        <label class="hobbyContainer">
                            <input type="checkbox" value="{{opt}}" (change)="onCheckboxChange($event)">
                            <span class="hobbyCheckMark"></span>
                            <div class="hobbyContent">{{opt}}</div>
                        </label>
                        <div [ngStyle]="{'display': last? 'block': 'none'}" [ngClass]="languageChange ? 'othersInputBoxSurveyForTamil' : 'othersInputBoxSurvey' ">
                            <input type="text" class="hobbyOthersInput" [(ngModel)]="this.otherHobbyInput" [ngModelOptions]="{standalone: true}" (ngModelChange)="checkOtherHobby($event)" placeholder="Please specify" [disabled]="!this.othersHobbyInput">
                            <div class="hobbyOthersLine"></div>
                        </div>
                    </div>
                    <label *ngIf="question.inputType == 'radio' && question.formControlName != 'specialActivity'" class="internetContainer1">{{opt}}
                        <input type="radio" name="{{question.formControlName}}" formControlName="{{question.formControlName}}" value="{{opt}}">
                        <span class="checkmark"></span>
                    </label>
                    <!-- special activity -->
                    <label *ngIf="question.inputType == 'radio' && question.formControlName == 'specialActivity'" class="internetContainer1">{{opt}}
                        <input type="radio" name="{{question.formControlName}}" (change)="onChangeActivityRadio($event, question.formControlName)" value="{{opt}}">
                        <span class="checkmark"></span>
                    </label>
                    <div [ngStyle]="{'display': last? 'block': 'none'}" *ngIf="question.formControlName == 'specialActivity'" [ngClass]="languageChange ? 'otherActivityBoxForTamil' : 'otherActivityBox'">
                        <input type="text" class="hobbyOthersInput" [(ngModel)]="this.otherActivityInput" [ngModelOptions]="{standalone: true}" (ngModelChange)="checkOtherActivity($event)" placeholder="Please specify" [disabled]="!this.otherActivity">
                        <div class="hobbyOthersLine"></div>
                    </div>
                </div>
                <textarea *ngIf="question.inputType == 'input'" placeholder="Please write your answer here" formControlName="phoneApp" class="phoneAppInput" rows="6"></textarea>
            <!-- Validating ------------------------->
                <!-- <div [ngStyle]="{'display': first ? 'block': 'none'}">
                    <div *ngIf="this.aboutSurveyPhase2Form.controls['hobbiesList'].errors?.['required']" class="hobbyErrorMsg">
                        Check box required, Choose atleast any one
                    </div>
                </div> -->
                <div *ngIf="question.formControlName?.invalid && (question.formControlName?.dirty || question.formControlName?.touched)" class="errorsBox">
                    <div *ngIf="question.formControlName?.errors?.['required']" class="consetnErrorMsg">
                        Choose any one
                    </div>
                </div>
            </div>
        <!-- Next button box ----------------------------------------->
            <div class="phase2NextBtnBox">
                <button class="phase2NextBtn" [disabled]="!this.aboutSurveyPhase2Form.valid" (click)="submitPhase2()">Next</button>
            </div>
        </div>
        </form>
    </div>
</div>